import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";

import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import CheckBox from "shared/components/FormElements/CheckBox";
import NotFound from "shared/components/UIElements/NotFound";

import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { isActiveOptions, criminalRecordTypes, graduationTypes } from "shared/util/types";
import DatePicker from "shared/components/FormElements/DatePicker";

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";

const InventoryDriverItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { driverDetails, driverOptions, vehicles } = useContext(StoreContext);

  const itemType = "driverdetail";
  const endPointUpdate = "driverdetails";
  const endPointDelete = "driverdetails";
  const endPointCreate = "driverdetails";

  // Initialize form with all fields from the schema
  const [formState, inputHandler, setFormData] = useForm(
    {
      driver: { value: null, isValid: false },
      identity: { value: null, isValid: false },
      startDate: { value: "", isValid: true },
      endDate: { value: "", isValid: true },
      companySgkRegistration: { value: "", isValid: true },
      supplierSgkRegistration: { value: "", isValid: true },
      birthDate: { value: "", isValid: true },
      licenseInquiry: { value: "", isValid: true },
      address: { value: null, isValid: true },
      iban: { value: false, isValid: true },
      license: { value: "", isValid: true },
      licensePhoto: { value: false, isValid: true },
      identityExpirationDate: { value: "", isValid: true },
      identityPhoto: { value: false, isValid: true },
      criminalRecord: { value: "", isValid: true },
      criminalRecordDate: { value: "", isValid: true },
      criminalRecordPhoto: { value: false, isValid: true },
      srcCertificate: { value: "", isValid: true },
      srcCertificateStartDate: { value: "", isValid: true },
      srcCertificatePhoto: { value: false, isValid: true },
      psychotechnicEndDate: { value: "", isValid: true },
      psychotechnicPhoto: { value: false, isValid: true },
      iskurEmploymentRegistryPhoto: { value: false, isValid: true },
      identityRegistryPhoto: { value: false, isValid: true },
      photo: { value: false, isValid: true },
      militaryStatusPhoto: { value: false, isValid: true },
      healthReportPhoto: { value: false, isValid: true },
      graduation: { value: null, isValid: true },
      graduationCertificatePhoto: { value: false, isValid: true },
      isActive: { value: false, isValid: true },
      notes: { value: "", isValid: true },
      licenseExpirationDate: { value: "", isValid: true },
      licenseInquiryDate: { value: "", isValid: true },
    },
    false
  );

  // Fetching item data
  useEffect(() => {
    const fetchItem = async () => {
      if (!isNewItem) {
        setIsItemLoaded(false);
        try {
          const currentDriverDetail = driverDetails.find((driverDetail) => driverDetail.id === itemId);
          if (currentDriverDetail) {
            setLoadedItem(currentDriverDetail);
          }
        } catch (err) {
          console.log(err);
        }
        setIsItemLoaded(true);
      } else {
        setIsItemLoaded(true);
      }
    };
    fetchItem();
  }, [itemId, driverDetails, isNewItem, setFormData]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();
    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    // Build the object to send (spelled out)
    const infoToBeSent = {
      driver: formState.inputs.driver.value,
      identity: formState.inputs.identity.value,
      startDate: formState.inputs.startDate.value,
      endDate: formState.inputs.endDate.value,
      companySgkRegistration: formState.inputs.companySgkRegistration.value,
      supplierSgkRegistration: formState.inputs.supplierSgkRegistration.value,
      birthDate: formState.inputs.birthDate.value,
      licenseInquiry: formState.inputs.licenseInquiry.value,
      address: formState.inputs.address.value,
      iban: formState.inputs.iban.value,
      license: formState.inputs.license.value,
      licensePhoto: formState.inputs.licensePhoto.value,
      identityExpirationDate: formState.inputs.identityExpirationDate.value,
      identityPhoto: formState.inputs.identityPhoto.value,
      criminalRecord: formState.inputs.criminalRecord.value,
      criminalRecordDate: formState.inputs.criminalRecordDate.value,
      criminalRecordPhoto: formState.inputs.criminalRecordPhoto.value,
      srcCertificate: formState.inputs.srcCertificate.value,
      srcCertificateStartDate: formState.inputs.srcCertificateStartDate.value,
      srcCertificatePhoto: formState.inputs.srcCertificatePhoto.value,
      psychotechnicEndDate: formState.inputs.psychotechnicEndDate.value,
      psychotechnicPhoto: formState.inputs.psychotechnicPhoto.value,
      iskurEmploymentRegistryPhoto: formState.inputs.iskurEmploymentRegistryPhoto.value,
      identityRegistryPhoto: formState.inputs.identityRegistryPhoto.value,
      photo: formState.inputs.photo.value,
      militaryStatusPhoto: formState.inputs.militaryStatusPhoto.value,
      healthReportPhoto: formState.inputs.healthReportPhoto.value,
      graduation: formState?.inputs?.graduation?.value || undefined,
      graduationCertificatePhoto: formState.inputs.graduationCertificatePhoto.value,
      isActive: formState.inputs.isActive.value,
      notes: formState.inputs.notes.value,
      licenseExpirationDate: formState.inputs.licenseExpirationDate.value,
      licenseInquiryDate: formState.inputs.licenseInquiryDate.value,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      window.toast.error(t("httpCodes." + err.code));
    }
  };

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  if (!isItemLoaded && !isNewItem) {
    return <p>Loading...</p>;
  }

  if (!isNewItem && isItemLoaded && !loadedItem && !error) {
    return <NotFound item={itemType} />;
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <div className="horizontal-flex" style={{ justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>
              <CustomSelect
                options={driverOptions}
                id="driver"
                onInput={inputHandler}
                label="Sürücü"
                initialValue={
                  driverOptions.find((option) => option.value.toString() === loadedItem?.driver?.toString()) || null
                }
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
              ></CustomSelect>
            </div>
            <CustomSelect
              options={isActiveOptions}
              id="isActive"
              onInput={inputHandler}
              label="Çalışma Durumu"
              initialValue={
                isActiveOptions.find((option) => option.value.toString() === loadedItem?.isActive.toString())
                  ? isActiveOptions.find((option) => option.value.toString() === loadedItem?.isActive.toString())
                  : isActiveOptions[1]
              }
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>

            <Input
              id="identity"
              element="input"
              type="text"
              label="Kimlik Numarası"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Kimlik numarası giriniz."
              initialValue={loadedItem?.identity || ""}
              initialValid={isNewItem ? false : true}
              onInput={inputHandler}
            />

            {!isNewItem && (
              <Input
                id="vehicle"
                element="input"
                type="text"
                label="Mevcut Araç (Araçlar sekmesinden değiştirilebilir)"
                validators={[VALIDATOR_REQUIRE()]}
                disabled={true}
                initialValue={vehicles.find((vehicle) => vehicle.driver === loadedItem?.driver)?.plate || "-"}
                initialValid={true}
                onInput={inputHandler}
              />
            )}
          </div>

          {!isNewItem && (
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <div className="horizontal-flex" style={{ alignItems: "center", justifyContent: "flex-start" }}>
                <div>
                  <Button type="button" href={`https://drive.google.com/drive/folders/${loadedItem?.folder?.id}`}>
                    Dosyalara Git
                  </Button>
                </div>
              </div>
            </div>
          )}

          <Input
            id="notes"
            element="textarea"
            type="text"
            label="Notlar"
            rows={7}
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.notes || ""}
            initialValid={true}
            onInput={inputHandler}
          />

          <div className="horizontal-flex">
            <div
              className="vertical-flex red-group-container"
              style={{ paddingTop: "15px", paddingBottom: "7px", maxWidth: "800px", marginLeft: "0" }}
            >
              <div style={{ flex: 2 }}>
                <Input
                  id="iban"
                  element="input"
                  type="text"
                  label="IBAN"
                  validators={[VALIDATOR_NONE()]}
                  initialValue={loadedItem?.iban || ""}
                  initialValid={true}
                  onInput={inputHandler}
                />
              </div>
              <div style={{ flex: 3 }}>
                <Input
                  id="address"
                  element="input"
                  type="text"
                  label="Adres"
                  validators={[VALIDATOR_NONE()]}
                  initialValue={loadedItem?.address || ""}
                  initialValid={true}
                  onInput={inputHandler}
                />
              </div>
              <DatePicker
                id="birthDate"
                onInput={inputHandler}
                label="Doğum Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.birthDate?.toString() || ""}
              ></DatePicker>

              <div className="horizontal-flex" style={{ justifyContent: "flex-start", maxWidth: "400px", margin: "0" }}>
                <DatePicker
                  id="startDate"
                  onInput={inputHandler}
                  label="İşe Başlama Tarihi"
                  initialValid={true}
                  validators={[VALIDATOR_NONE()]}
                  initialValue={loadedItem?.startDate?.toString() || ""}
                ></DatePicker>

                <DatePicker
                  id="endDate"
                  onInput={inputHandler}
                  label="İşten Ayrılma Tarihi"
                  initialValid={true}
                  validators={[VALIDATOR_NONE()]}
                  initialValue={loadedItem?.endDate?.toString() || ""}
                ></DatePicker>
              </div>
            </div>

            {!isNewItem && (
              <div className="vertical-flex red-group-container" style={{ paddingTop: "0px" }}>
                <CheckBox
                  id="identityPhoto"
                  label="Kimlik Fotoğrafları Yüklendi"
                  initialValue={loadedItem?.identityPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />
                <CheckBox
                  id="photo"
                  label="Sürücü Fotoğrafı Yüklendi"
                  initialValue={loadedItem?.photo}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />
                <CheckBox
                  id="licensePhoto"
                  label="Ehliyet Fotoğrafları Yüklendi"
                  initialValue={loadedItem?.licensePhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />
                <CheckBox
                  id="iskurEmploymentRegistryPhoto"
                  label="İşKur Belgesi Yüklendi"
                  initialValue={loadedItem?.iskurEmploymentRegistryPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="psychotechnicPhoto"
                  label="SRC Fotoğrafları Yüklendi"
                  initialValue={loadedItem?.psychotechnicPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="identityRegistryPhoto"
                  label="Nüfus Kayıt Örneği Yüklendi"
                  initialValue={loadedItem?.identityRegistryPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="criminalRecordPhoto"
                  label="Sabıka Kaydı Fotoğrafları Yüklendi"
                  initialValue={loadedItem?.criminalRecordPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="graduationCertificatePhoto"
                  label="Mezuniyet Belgesi Yüklendi"
                  initialValue={loadedItem?.graduationCertificatePhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="militaryStatusPhoto"
                  label="Askerlik Durumu Belgesi Yüklendi"
                  initialValue={loadedItem?.militaryStatusPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="healthReportPhoto"
                  label="Sağlık Durumu Belgesi Yüklendi"
                  initialValue={loadedItem?.healthReportPhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />

                <CheckBox
                  id="srcCertificatePhoto"
                  label="SRC Fotoğrafları Yüklendi"
                  initialValue={loadedItem?.srcCertificatePhoto}
                  initialValid={true}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                />
              </div>
            )}
          </div>

          <div className="horizontal-flex">
            <div className="vertical-flex red-group-container">
              <Input
                id="license"
                element="input"
                type="text"
                label="Ehliyet"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.license || ""}
                initialValid={true}
                onInput={inputHandler}
              />
              <DatePicker
                id="licenseExpirationDate"
                onInput={inputHandler}
                label="Ehliyet Geçerlilik Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.licenseExpirationDate?.toString() || ""}
              ></DatePicker>
            </div>
            <div className="vertical-flex red-group-container">
              <Input
                id="licenseInquiry"
                element="input"
                type="text"
                label="Ehliyet Sorgu"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.licenseInquiry || ""}
                initialValid={true}
                onInput={inputHandler}
              />
              <DatePicker
                id="licenseInquiryDate"
                onInput={inputHandler}
                label="Ehliyet Sorgu Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.licenseInquiryDate?.toString() || ""}
              ></DatePicker>
            </div>
            <div className="vertical-flex red-group-container">
              <Input
                id="companySgkRegistration"
                element="input"
                type="text"
                label="Şirket SGK Kaydı"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.companySgkRegistration || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <Input
                id="supplierSgkRegistration"
                element="input"
                type="text"
                label="Tedarikçi SGK Kaydı"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.supplierSgkRegistration || ""}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <CustomSelect
                options={criminalRecordTypes}
                id="criminalRecord"
                onInput={inputHandler}
                label="Sabıka Kaydı"
                initialValue={
                  criminalRecordTypes.find(
                    (option) => option.value.toString() === loadedItem?.criminalRecord?.toString()
                  )
                    ? criminalRecordTypes.find(
                        (option) => option.value.toString() === loadedItem?.criminalRecord?.toString()
                      )
                    : criminalRecordTypes[0]
                }
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              ></CustomSelect>

              <DatePicker
                id="criminalRecordDate"
                onInput={inputHandler}
                label="Sabıka Kaydı Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.criminalRecordDate?.toString() || ""}
              ></DatePicker>
            </div>
            <div className="vertical-flex red-group-container">
              <Input
                id="srcCertificate"
                element="input"
                type="text"
                label="SRC Belgesi"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.srcCertificate || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <DatePicker
                id="srcCertificateStartDate"
                onInput={inputHandler}
                label="SRC Belgesi Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.srcCertificateStartDate?.toString() || ""}
              ></DatePicker>
            </div>
          </div>

          <div className="horizontal-flex" style={{ width: "59.5%", marginLeft: "0" }}>
            <div className="vertical-flex red-group-container">
              <DatePicker
                id="identityExpirationDate"
                onInput={inputHandler}
                label="Nüfus Cüzdanı Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.identityExpirationDate?.toString() || ""}
              ></DatePicker>
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="psychotechnicEndDate"
                onInput={inputHandler}
                label="Psiko Teknik Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.psychotechnicEndDate?.toString() || ""}
              ></DatePicker>
            </div>

            <div className="vertical-flex red-group-container">
              <CustomSelect
                options={graduationTypes}
                id="graduation"
                onInput={inputHandler}
                label="Eğitim"
                initialValue={
                  graduationTypes.find((option) => option.value.toString() === loadedItem?.graduation?.toString())
                    ? graduationTypes.find((option) => option.value.toString() === loadedItem?.graduation?.toString())
                    : graduationTypes[0]
                }
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              ></CustomSelect>
            </div>
          </div>

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !formState.inputs.identity.value || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default InventoryDriverItem;
