import React from "react";
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "24px" }}>
      <h1 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>{t("terms.termsOfService")}</h1>
      <p style={{ marginBottom: "16px" }}>{t("terms.termsOfServiceIntro")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("terms.registration")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("terms.registrationDetails")}</p>
      <p style={{ marginBottom: "16px" }}>{t("terms.security")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("terms.usage")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("terms.usageDetails")}</p>
      <p style={{ marginBottom: "16px" }}>{t("terms.modification")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("terms.privacy")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("terms.privacyPolicy")}</p>
      <p style={{ marginBottom: "16px" }}>{t("terms.privacyRestriction")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("terms.dispute")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("terms.disputeLaw")}</p>
      <p style={{ marginBottom: "16px" }}>{t("terms.disputeCourt")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("terms.effectiveness")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("terms.effectivenessDetails")}</p>
    </div>
  );
};

export default TermsOfService;
