import React, { useState, useContext, useEffect } from "react";
import Layout from "pages/shared/Layout";

import { StoreContext } from "shared/context/store-context";
import { ownerShipTypes, yesNoUnknowTypes, plateTypes, criminalRecordTypes, graduationTypes } from "shared/util/types";
import { formatDate } from "shared/util/time";

import InventoryDriverItem from "./InventoryDriverItem";

const InventoryDrivers = () => {
  const itemType = "driverdetail";
  const listName = "inventory_drivers";

  const { driverDetails, drivers, DeleteDriverDetail, UpdateDriverDetail, InsertDriverDetail, driverOptions } =
    useContext(StoreContext);

  const [pageItems, setPageItems] = useState();

  const shouldHighlightDate = (date) => {
    if (!date) {
      return true;
    }
    const currentDate = new Date();
    const oneMonthAfter = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    return date <= formatDate(oneMonthAfter).split(" ")[0];
  };

  useEffect(() => {
    setPageItems(driverDetails);
  }, [driverDetails]);

  const listColumns = [
    {
      field: "driver",
      headerName: "İsim",
      width: 300,
      valueGetter: (params) => drivers.find((user) => user?._id === params?.row?.driver)?.name || "",
    },
    {
      field: "isActive",
      headerName: "Aktif",
      width: 100,
      valueGetter: (params) => (params?.row?.isActive ? "Evet" : "Hayır"),
    },
    {
      field: "identity",
      headerName: "Kimlik No",
      width: 150,
    },
    { field: "iban", headerName: "IBAN", width: 200 },

    { field: "birthDate", headerName: "Doğum Tarihi", width: 150 },
    { field: "license", headerName: "Ehliyet", width: 100 },

    { field: "address", headerName: "Adres", width: 200 },

    { field: "companySgkRegistration", headerName: "Şirket SGK", width: 200 },
    { field: "supplierSgkRegistration", headerName: "Tedarikçi SGK", width: 200 },
    {
      field: "criminalRecord",
      headerName: "Sabıka",
      width: 100,
      valueGetter: (params) =>
        criminalRecordTypes.find((type) => type.value === params?.row?.criminalRecord)?.label || "",
    },
    { field: "criminalRecordDate", headerName: "Sabıka Tarihi", width: 150 },
    { field: "identityExpirationDate", headerName: "Nüfus Cüzdanı", width: 150 },
    { field: "srcCertificate", headerName: "SRC", width: 100 },
    { field: "srcCertificateStartDate", headerName: "SRC Başlangıç", width: 150 },
    { field: "licenseInquiry", headerName: "Ehliyet Sorgu", width: 150 },
    { field: "licenseInquiryDate", headerName: "Ehliyet Sorgu Tarihi", width: 150 },
    {
      field: "graduation",
      headerName: "Eğitim",
      width: 150,
      valueGetter: (params) => graduationTypes.find((type) => type.value === params?.row?.graduation)?.label || "-",
    },
    {
      field: "psychotechnicEndDate",
      headerName: "Psikoteknik Bitiş",
      width: 150,
      cellClassName: (params) =>
        shouldHighlightDate(params?.row?.psychotechnicEndDate) ? "hightlight-red" : undefined,
    },
    {
      field: "licenseExpirationDate",
      headerName: "Ehliyet Bitiş",
      width: 150,
      cellClassName: (params) =>
        shouldHighlightDate(params?.row?.licenseExpirationDate) ? "hightlight-red" : undefined,
    },

    {
      field: "licensePhoto",
      headerName: "Ehliyet Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.licensePhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.licensePhoto ? undefined : "hightlight-red"),
    },
    {
      field: "identityPhoto",
      headerName: "Kimlik Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.identityPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.identityPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "criminalRecordPhoto",
      headerName: "Sabıka Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.criminalRecordPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.criminalRecordPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "srcCertificatePhoto",
      headerName: "SRC Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.srcCertificatePhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.srcCertificatePhoto ? undefined : "hightlight-red"),
    },
    {
      field: "psychotechnicPhoto",
      headerName: "Psikoteknik Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.psychotechnicPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.psychotechnicPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "iskurEmploymentRegistryPhoto",
      headerName: "İşkur Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.iskurEmploymentRegistryPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.iskurEmploymentRegistryPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "identityRegistryPhoto",
      headerName: "Kimlik Kayıt Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.identityRegistryPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.identityRegistryPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "photo",
      headerName: "Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.photo ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.photo ? undefined : "hightlight-red"),
    },
    {
      field: "militaryStatusPhoto",
      headerName: "Askerlik Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.militaryStatusPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.militaryStatusPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "healthReportPhoto",
      headerName: "Sağlık Rapor Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.healthReportPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.healthReportPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "graduationCertificatePhoto",
      headerName: "Mezuniyet Foto",
      width: 150,
      valueGetter: (params) => (params?.row?.graduationCertificatePhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.graduationCertificatePhoto ? undefined : "hightlight-red"),
    },
    { field: "notes", headerName: "Notlar", width: 200 },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      showSelectedYear={false}
      isAlwaysFullScreen={true}
      hideCreateNewItem={false}
      hideRightHeader={false}
      SelectedItemComponent={InventoryDriverItem}
      NewItemComponent={InventoryDriverItem}
      DeleteItem={DeleteDriverDetail}
      InsertItem={InsertDriverDetail}
      UpdateItem={UpdateDriverDetail}
    />
  );
};

export default InventoryDrivers;
