import React, { useContext, useState } from "react";
import Button from "shared/components/FormElements/Button";
import Input from "shared/components/FormElements/Input";

import { StoreContext } from "shared/context/store-context";
import CustomSelect from "shared/components/FormElements/Select";
import { useForm } from "shared/hooks/form-hook";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { VALIDATOR_TIME_EMPTY_ACCEPTED } from "shared/util/validators";
import { CheckTimeString_EmptyAllowed } from "shared/util/time";

import { findUserInServices } from "shared/util/relationaldata";

const Personel = ({
  users,
  services,
  servicesOptions,
  date,
  school,
  displayName,
  onAddPersonel,
  onRemovePersonel,
  onChangeUserService,
  onChangeUserTransferTime,
  onSendSms,
  onSendAllSms,
}) => {
  const { userOptions } = useContext(StoreContext);

  const [disabledSmsSend, setDisabledSmsSend] = useState(false);
  const [disabledSmses, setDisabledSmses] = useState(false);

  const [formStatePersonel, inputHandlerPersonel] = useForm(
    {
      personelUnique: { value: "", isValid: true },
    },
    true
  );

  const addUniquePersonelHandler = () => {
    onAddPersonel(formStatePersonel.inputs.personelUnique.value);
  };

  const getUserService = (userId) => {
    const foundService = findUserInServices(services, userId);
    if (foundService) {
      return foundService;
    }
    return null;
  };

  const getUserSmses = (userId) => {
    const foundService = getUserService(userId);
    if (foundService) {
      return foundService?.usersdetails?.find((userdetail) => userdetail.user === userId)?.smses || [];
    }
    return [];
  };

  const getUserTransferTime = React.useCallback(
    (userId) => {
      const foundService = findUserInServices(services, userId);
      if (foundService) {
        return foundService?.usersdetails?.find((userdetail) => userdetail.user === userId)?.transfertime || "";
      }
      return "";
    },
    [services] // Dependency on services
  );

  const isUserTurkish = (userId) => {
    const currentUser = users.find((user) => user.id === userId);
    if (currentUser.phonenumber.startsWith("+")) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <div className="item-form" style={{ paddingBottom: "0px" }}>
        <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
          <div style={{ display: "flex", flex: 1, maxWidth: "700px", gap: "20px", justifyContent: "flex-start" }}>
            <CustomSelect
              options={userOptions}
              id="personelUnique"
              isMulti={false}
              initialValid={true}
              onInput={inputHandlerPersonel}
            ></CustomSelect>

            <Button type="button" danger onClick={addUniquePersonelHandler}>
              Personel Ekle
            </Button>
          </div>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <Button
              type="button"
              danger
              disabled={disabledSmsSend}
              isLoading={disabledSmsSend}
              onClick={async () => {
                setDisabledSmsSend(true);
                setDisabledSmses(true);
                let messages = [];
                for (let user of users) {
                  messages.push({
                    service: getUserService(user.id),
                    userId: user.id,
                    text: document.getElementById("text" + user.id.toString().trim()).value,
                  });
                  //sleep 1 second
                }
                await onSendAllSms(messages);
                setDisabledSmsSend(false);
                setDisabledSmses(false);
              }}
            >
              Tüm Smsleri Gönder
            </Button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        {users && users.length > 0 && services && getUserService && (
          <TableContainer
            component={Paper}
            sx={{
              minWidth: 650,
              margin: 0,
              padding: 0,
              //   marginBottom: "20px",
              border: "none",
              boxShadow: "none",
              spacing: 0,
              overflow: "visible",
            }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start ",
                // margin: "auto",
                zIndex: -1,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ paddingLeft: 0 }}>
                    <b>Personel</b>
                  </TableCell>
                  <TableCell sx={{ paddingLeft: 0 }}>
                    <b>Servis</b>
                  </TableCell>
                  <TableCell>
                    <b>Biniş Saati</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <b>Gönderilen Mesajlar</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Mesaj Gönder</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((user) => (
                    <TableRow
                      sx={{
                        verticalAlign: "initial",
                        "td, th": { paddingBottom: "15px", paddingTop: "15px", alignContent: "flex-start" },
                      }}
                      key={user.id}
                    >
                      <TableCell component="th" width="2%" scope="row" sx={{ padding: 0 }}>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={() => onRemovePersonel(user.id)}
                          sx={{ marginRight: "5px" }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" width="15%" scope="row">
                        <div>
                          {user.name} <br /> {user.phonenumber}
                        </div>
                      </TableCell>
                      <TableCell component="th" width="15%" scope="row" sx={{ padding: 0 }}>
                        <CustomSelect
                          id={"personelservice" + user.id}
                          options={servicesOptions.filter((serviceOption) =>
                            services.map((service) => service.id).includes(serviceOption.value)
                          )}
                          isMulti={false}
                          onInput={inputHandlerPersonel}
                          initialValid={false}
                          initialValue={
                            servicesOptions.find((option) => option.value === getUserService(user.id)?.id) || null
                          }
                          fireChange={onChangeUserService.bind(this, user.id)}
                          style={{ margin: "0" }}
                        />
                      </TableCell>

                      <TableCell component="th" width="8%" scope="row" sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Input
                          id={"personeltransfertime" + user.id}
                          element="input"
                          type="text"
                          onInput={inputHandlerPersonel}
                          initialValid={true}
                          initialValue={getUserTransferTime(user.id)}
                          validators={[VALIDATOR_TIME_EMPTY_ACCEPTED()]}
                          dontShowError={true}
                        />
                      </TableCell>

                      <TableCell component="th" width="7%" scope="row" sx={{ padding: 0 }}>
                        <Button
                          disabled={
                            !CheckTimeString_EmptyAllowed(
                              document.getElementById("personeltransfertime" + user.id)?.value || ""
                            ) || !getUserService(user.id)
                          }
                          onClick={() =>
                            onChangeUserTransferTime(
                              user.id,
                              document.getElementById("personeltransfertime" + user.id)?.value || ""
                            )
                          }
                        >
                          Kaydet
                        </Button>
                      </TableCell>
                      <TableCell component="th" width="13%" scope="row" sx={{ alignContent: "flex-start" }}>
                        <div>
                          {getUserSmses(user.id).length > 0 ? (
                            <div>
                              {getUserSmses(user.id).map((sms) => (
                                <div key={sms.id}>
                                  {sms.text}
                                  <br />
                                  <i>{sms?.date}</i>
                                  <br />
                                  <br />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </TableCell>

                      <TableCell scope="row" width="40%" align="right" sx={{ padding: 0 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "100%",
                            paddingRight: "16px",
                          }}
                        >
                          <textarea
                            style={{ margin: "16px", marginBottom: "0px", marginTop: "0px", width: "400px" }}
                            key={"SMS" + user.id + getUserTransferTime(user.id)}
                            id={"text" + user.id.toString().trim()}
                            cols="30"
                            rows="3"
                            maxLength={155}
                            defaultValue={`${displayName}${
                              isUserTurkish(user.id)
                                ? " uçuşu için servis saatiniz yaklaşık "
                                : ". Your shuttle pick up time is: "
                            }${getUserTransferTime(user.id)}. ${
                              isUserTurkish(user.id) ? "İletişim için: " : "For your questions: "
                            }+9${school.phonenumber.trim()}.`}
                          ></textarea>
                          <div width="60px" style={{ marginRight: "0" }}>
                            <Button
                              type="button"
                              danger
                              disabled={
                                !getUserService(user.id) ||
                                !getUserTransferTime(user.id) ||
                                disabledSmses ||
                                document.getElementById("text" + user.id.toString().trim())?.value?.length === 0
                              }
                              // onClick={() => SendSMS(user)}
                              onClick={async () => {
                                setDisabledSmses(true);
                                await onSendSms(
                                  getUserService(user.id),
                                  user.id,
                                  document.getElementById("text" + user.id.toString().trim()).value
                                );
                                setDisabledSmses(false);
                              }}
                            >
                              SMS Gönder
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      asd
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default Personel;
