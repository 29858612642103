import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "shared/context/auth-context";
import "./NavLinks.css";
import { CLIENT_TYPES, SERVICE_TYPES, PERMISSION_TYPES } from "shared/util/constants";

const NavLinks = () => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  let studentsLinkHeader = "...";
  let schoolsLinkHeader = "...";
  let usersLinkHeader = "...";

  if (auth.serviceType === SERVICE_TYPES.SCHOOL) {
    studentsLinkHeader = t("students");
    schoolsLinkHeader = t("schools");
    usersLinkHeader = t("parents");
  } else if (auth.serviceType === SERVICE_TYPES.SOUTHWIND || auth.serviceType === SERVICE_TYPES.PERSONEL) {
    studentsLinkHeader = t("employeeAddresses");
    schoolsLinkHeader = t("clients");
    usersLinkHeader = t("employees");
  }

  return (
    <ul className="nav-links">
      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_USERS] && (
        <li>
          <NavLink to="/users" end>
            {t(usersLinkHeader)}
          </NavLink>
        </li>
      )}

      {/* Simdilik duraklar yok */}
      {auth.isLoggedIn &&
        auth.serviceType === SERVICE_TYPES.PERSONEL &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_STOPS] && (
          <li>
            <NavLink to="/stops" end>
              {t("stops")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn &&
        (auth.serviceType === SERVICE_TYPES.SCHOOL || auth.serviceType === SERVICE_TYPES.SOUTHWIND) &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_STUDENTS] && (
          <li>
            <NavLink to="/students" end>
              {studentsLinkHeader}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn &&
        (auth.serviceType === SERVICE_TYPES.SCHOOL || auth.serviceType === SERVICE_TYPES.SOUTHWIND) &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_NOTIFICATIONS] && (
          <li>
            <NavLink to="/notifications" end>
              {t("notifications")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn &&
        (auth.serviceType === SERVICE_TYPES.SCHOOL || auth.serviceType === SERVICE_TYPES.SOUTHWIND) &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_VEHICLE_ROUTES] && (
          <li>
            <NavLink to="/vehicleroutes" end>
              {t("vehicleRoutes")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn &&
        auth.serviceType === SERVICE_TYPES.PERSONEL &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_STANDARD_PERSONEL_SERVICES] && (
          <li>
            <NavLink to="/standardpersonelservices" end>
              {t("standardServices")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn &&
        auth.serviceType === SERVICE_TYPES.PERSONEL &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_SHIFT_PERSONEL_SERVICES] && (
          <li>
            <NavLink to="/shiftpersonelservices" end>
              {t("shiftServices")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_TRANSFERS] && (
        <li>
          <NavLink to="/transfermanagement" end>
            {t("transfers")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_IKARUS] && (
        <li>
          <NavLink to="/ikarus" end>
            {t("ikarus")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn &&
        auth.serviceType === SERVICE_TYPES.PERSONEL &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_SHIFTS] && (
          <li>
            <NavLink to="/shifts" end>
              {t("shifts")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn &&
        auth.serviceType === SERVICE_TYPES.PERSONEL &&
        auth.permissions[PERMISSION_TYPES.ACCESS_TO_SHIFT_MANAGEMENT] && (
          <li>
            <NavLink to="/shiftmanagement" end>
              {t("shiftmanagement")}
            </NavLink>
          </li>
        )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_LIVE] && (
        <li>
          <NavLink to="/live" end>
            {t("watchVehicle")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_ACCOUNTING] && (
        <li>
          <NavLink to="/accounting" end>
            {t("accounting")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_ANALYSIS] && (
        <li>
          <NavLink to="/analysis" end>
            {t("analysis")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_DRIVERS] && (
        <li>
          <NavLink to="/drivers" end>
            {t("drivers")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_INVENTORY] && (
        <li>
          <NavLink to="/inventory">Envanter</NavLink>
        </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <NavLink to="/schools">{t(schoolsLinkHeader)}</NavLink>
        </li>
      )}

      {auth.isLoggedIn && auth.permissions[PERMISSION_TYPES.ACCESS_TO_ADMIN] && (
        <li>
          <NavLink to="/admin">Admin</NavLink>
        </li>
      )}

      {/* {auth.isLoggedIn && auth.type === CLIENT_TYPES.CLIENT && (
        <li>
          <NavLink to="/userswebsite" end>
            {t("userswebsite")}
          </NavLink>
        </li>
      )} */}

      {/* {!auth.isLoggedIn && (
        <li>
          <NavLink to="/login">{t("login")}</NavLink>
        </li>
      )} */}
      {/* {auth.isLoggedIn && (
        <li>
          <button onClick={auth.logout}>{t("logout")}</button>
        </li>
      )} */}
    </ul>
  );
};

export default NavLinks;
