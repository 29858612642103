import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";
import { SERVICE_TYPES } from "shared/util/constants";
import { getColumnValue } from "shared/util/datagrid";

import TransferRegionItem from "./TransferRegionItem";

const Users = () => {
  const { t } = useTranslation();
  const itemType = "transferregion";
  const listName = "main_transferregions";

  const {
    transferregions,
    userTypes,
    schoolOptions,
    serviceType,
    standartPersonelServices,
    DeleteTransferRegion,
    InsertTransferRegion,
    UpdateTransferRegion,
  } = useContext(StoreContext);

  const { permissions } = useContext(AuthContext);

  const pageItems = transferregions;

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push(getColumnValue("school", t("client"), schoolOptions, "school", 300));
  }

  listColumns.push({ field: "name", headerName: t("name"), width: 300 });
  listColumns.push({ field: "amount", headerName: t("amount"), width: 200 });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={TransferRegionItem}
      NewItemComponent={TransferRegionItem}
      DeleteItem={DeleteTransferRegion}
      InsertItem={InsertTransferRegion}
      UpdateItem={UpdateTransferRegion}
    />
  );
};

export default Users;
