import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
import NotFound from "shared/components/UIElements/NotFound";

import MapWithTransferTimesContainer from "components/shared/MapWithTransferTimesContainer";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import Skeleton from "@mui/material/Skeleton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// #region Components
import Button from "shared/components/FormElements/Button";
import { useBackend } from "shared/hooks/backend-hook";

import { StoreContext } from "shared/context/store-context";
import { is } from "date-fns/locale";

const VehicleRouteSms = ({ students, drivers, vehicleRouteId }) => {
  const { t } = useTranslation();
  const { sendSMS, getSMSByVehicleRoute } = useBackend();
  const { GetParentFromStudent } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingSms, setIsSendingSms] = useState(false);
  const [currentSmses, setCurrentSmses] = useState([]);

  const loadSMS = useCallback(async () => {
    setCurrentSmses(await getSMSByVehicleRoute(vehicleRouteId));
  }, [getSMSByVehicleRoute, vehicleRouteId]);

  const SendSMSToStudent = async (studentId) => {
    setIsSendingSms(true);
    const message = document.getElementById("text" + studentId).value.trim();
    const parent = GetParentFromStudent(studentId);

    const user = parent?.id.toString();
    const phone = parent?.phonenumber;

    if (!phone || !message) {
      window.toast.error(t("actionFailed"));
      return;
    }

    try {
      await sendSMS(phone, message, user, studentId, vehicleRouteId);
    } catch (err) {}

    await loadSMS();
    setIsSendingSms(false);
  };

  const SendSMSToDriver = async (driverId) => {
    setIsSendingSms(true);
    const message = document.getElementById("text" + driverId).value.trim();
    const foundPhone = drivers.find((driver) => driver.id === driverId)?.phonenumber;

    if (!foundPhone || !message) {
      window.toast.error(t("actionFailed"));
      return;
    }

    try {
      await sendSMS(foundPhone, message, driverId, null, vehicleRouteId);
    } catch (err) {}

    await loadSMS();
    setIsSendingSms(false);
  };

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    setIsLoading(true);
    await loadSMS();
    setIsLoading(false);
  }, [loadSMS]);

  useEffect(() => {
    fetchItem();
  }, [vehicleRouteId, students, drivers, fetchItem]);

  //   if (isLoading) {
  //     return (
  //       <div className="center">
  //         <LoadingSpinner asOverlay />
  //       </div>
  //     );
  //   }

  return (
    <React.Fragment>
      <div>
        <TableContainer component={Paper}>
          <Table
            sx={{ width: "100%", justifyContent: "center", margin: "auto" }}
            stickyHeader
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell width="10%">
                  <b>{t("user.title")}</b>
                </TableCell>
                <TableCell width="45%">
                  <b>{t("sentMessages")}</b>
                </TableCell>
                <TableCell width="45%" align="right">
                  <b>{t("newMessageLimited")}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(drivers.length) &&
                drivers?.map((driver) => (
                  <TableRow key={driver.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <b>{t("driver")}</b>
                      <br /> {driver.name} <br /> {driver.phonenumber}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {isLoading ? (
                        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "150px" }} />
                      ) : currentSmses.filter((sms) => sms?.user?.id.toString() === driver?.id?.toString())?.length >
                        0 ? (
                        <div>
                          {currentSmses
                            .filter((sms) => sms?.user?.id.toString() === driver?.id?.toString())
                            .map((sms) => (
                              <div key={sms.id}>
                                {sms.text}
                                <br />
                                <i>{sms.date}</i>
                                <br />
                                <br />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div>{t("noMessage")}</div>
                      )}
                    </TableCell>

                    <TableCell scope="row" align="right">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <textarea
                          style={{ margin: "16px" }}
                          key={"SMS" + driver.id}
                          id={"text" + driver.id.toString().trim()}
                          cols="30"
                          rows="7"
                          maxLength="155"
                        ></textarea>
                        <div width="60px" style={{ marginRight: "16px" }}>
                          <Button type="button" danger onClick={() => SendSMSToDriver(driver?.id?.toString()?.trim())}>
                            {t("sendSMS")}
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}

              {students.map((student) => (
                <TableRow key={student.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {student.name} <br /> {GetParentFromStudent(student.id)?.phonenumber || "-"}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {isLoading ? (
                      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "150px" }} />
                    ) : currentSmses.filter((sms) => sms?.student?.id?.toString() === student?.id?.toString()).length >
                      0 ? (
                      <div>
                        {currentSmses
                          .filter((sms) => sms?.student?.id?.toString() === student?.id?.toString())
                          .map((sms) => (
                            <div key={sms.id}>
                              {sms.text}
                              <br />
                              <i>{sms.date}</i>
                              <br />
                              <br />
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div>{t("noMessage")}</div>
                    )}
                  </TableCell>

                  <TableCell scope="row" align="right">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <textarea
                        style={{ margin: "16px" }}
                        key={"SMS" + student.id}
                        id={"text" + student.id.toString().trim()}
                        cols="30"
                        rows="7"
                        maxLength="155"
                      ></textarea>
                      <div width="60px">
                        <Button
                          type="button"
                          disabled={!GetParentFromStudent(student.id)?.phonenumber || isLoading}
                          danger
                          onClick={() => SendSMSToStudent(student.id.toString().trim())}
                        >
                          {t("sendSMS")}
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default VehicleRouteSms;
