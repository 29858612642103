import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { getColumnValue } from "shared/util/datagrid";
import { transfertypes, transferStatus } from "shared/util/types";
import { isPickUp } from "shared/util/util";
import ManagedTransfer from "./ManagedTransfer";

const Transfers = ({ active }) => {
  const { t } = useTranslation();
  const itemType = "transfer";
  const listName = "main_transfers";

  const { transfers, schoolOptions, DeleteTransfer, InsertTransfer, UpdateTransfer } = useContext(StoreContext);

  const pageItems = useMemo(
    () => (active ? transfers.filter((transfer) => transfer?.done === false) : transfers),
    [active, transfers]
  );

  const listColumns = useMemo(() => {
    const columns = [];

    if (schoolOptions?.length > 1) {
      columns.push(getColumnValue("school", t("client"), schoolOptions, "school", 300));
    }

    columns.push({ field: "transferdate", headerName: t("date"), width: 200 });
    columns.push({ field: "name", headerName: t("name"), width: 400 });
    columns.push({
      field: "type",
      headerName: t("serviceType"),
      width: 200,
      valueGetter: (params) => transfertypes.find((item) => item?.value === params?.row?.type)?.label || "-",
    });

    columns.push({
      field: "pickupTransferTime",
      headerName: t("pickUpRouteClientArrivalTime"),
      width: 150,
      valueGetter: (params) => {
        const timeParts = params?.row?.transfertime?.split(" ");
        return isPickUp(params.row.type) && timeParts?.[4] ? timeParts[4].substring(0, 5) : "-";
      },
    });

    columns.push({
      field: "dropOffTransferTime",
      headerName: t("dropOffRouteClientArrivalTime"),
      width: 150,
      valueGetter: (params) => {
        const timeParts = params?.row?.transfertime?.split(" ");
        return isPickUp(params.row.type) ? "-" : timeParts?.[4]?.substring(0, 5) || "-";
      },
    });

    columns.push({
      field: "done",
      headerName: "Durum",
      width: 150,
      valueGetter: (params) => transferStatus.find((item) => item.value === params.row.done)?.label || "-",
      cellClassName: (params) => (params.row.done ? "processdone" : "processongoing"),
    });

    return columns;
  }, [schoolOptions]);

  return (
    <Layout
      hideCreateNewItem
      isAlwaysFullScreen
      hideRightHeader
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={ManagedTransfer}
      NewItemComponent={ManagedTransfer}
      DeleteItem={DeleteTransfer}
      InsertItem={InsertTransfer}
      UpdateItem={UpdateTransfer}
    />
  );
};

export default React.memo(Transfers);
