import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";

import { StoreContext } from "shared/context/store-context";
import { paymentMethods, paymentTypes, banks } from "shared/util/types";
import { getContractYearLabel } from "shared/util/relationaldata";

import ReceiptItem from "./ReceiptItem";

const Receipts = (props) => {
  const { t } = useTranslation();
  const itemType = "receipt";
  const listName = "main_receipts";

  const {
    contracts,
    educationYearsOptions,
    schoolOptions,
    students,
    contractsOptions,
    receipts,
    selectedEducationYearId,
    DeleteReceipt,
    UpdateReceipt,
    InsertReceipt,
  } = useContext(StoreContext);

  const [pageItems, setPageItems] = useState();

  useEffect(() => {
    setPageItems(receipts.filter((item) => item?.contract?.year === selectedEducationYearId));
  }, [receipts, selectedEducationYearId]);

  const listColumns = [
    {
      field: "school",
      headerName: "Okul",
      width: 300,
      valueGetter: (params) => {
        try {
          return params.row.contract.student.school.name;
        } catch (e) {
          return "-";
        }
      },
    },
    {
      field: "year",
      headerName: "Yıl",
      width: 130,
      valueGetter: (params) => {
        try {
          return getContractYearLabel(params.row.contract.year);
        } catch (e) {
          return "-";
        }
      },
    },

    {
      field: "contract",
      headerName: "Fatura İsmi - Öğrenci",
      width: 400,
      valueGetter: (params) => {
        try {
          return params?.row.contract.payerName + " - " + params.row.contract?.student?.name;
        } catch (e) {
          return "-";
        }
      },
    },
    { field: "amount", headerName: "Meblağ", width: 150 },
    {
      field: "date",
      headerName: "Ödeme Tarihi",
      width: 150,
    },
    {
      field: "paymentMethod",
      headerName: "Ödeme Yöntemi",
      width: 150,

      valueGetter: (params) => {
        try {
          return paymentMethods.find((item) => item.value.toString() === params.row.paymentMethod.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "paymentType",
      headerName: "Ödeme Şekli",
      width: 150,

      valueGetter: (params) => {
        try {
          return paymentTypes.find((item) => item.value.toString() === params.row.paymentType.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "bank",
      headerName: "Banka",
      width: 150,
      valueGetter: (params) => {
        try {
          return banks.find((item) => item.value.toString() === params.row.bank.toString())?.label;
        } catch (e) {
          return "";
        }
      },
    },

    { field: "notes", headerName: "Notlar", width: 150 },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      showSelectedYear={true}
      isAlwaysFullScreen={false}
      hideCreateNewItem={false}
      hideRightHeader={false}
      // hideTopBarWhenSelected={true}
      SelectedItemComponent={ReceiptItem}
      NewItemComponent={ReceiptItem}
      DeleteItem={DeleteReceipt}
      InsertItem={InsertReceipt}
      UpdateItem={UpdateReceipt}
    />
  );
};

export default Receipts;
