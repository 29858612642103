import React, { useState, useContext, useEffect } from "react";
import Layout from "pages/shared/Layout";

import { StoreContext } from "shared/context/store-context";
import { ownerShipTypes, yesNoUnknowTypes, plateTypes } from "shared/util/types";
import { formatDate } from "shared/util/time";

import InventoryVehicleItem from "./InventoryVehicleItem";

const InventoryVehicles = () => {
  const itemType = "vehicle";
  const listName = "main_vehicles";

  const { vehicles, DeleteVehicle, UpdateVehicle, InsertVehicle, driverOptions } = useContext(StoreContext);

  const [pageItems, setPageItems] = useState();

  const shouldHighlightDate = (date) => {
    if (!date) {
      return true;
    }
    const currentDate = new Date();
    const oneMonthAfter = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    return date <= formatDate(oneMonthAfter).split(" ")[0];
  };

  useEffect(() => {
    setPageItems(vehicles);
  }, [vehicles]);

  const listColumns = [
    { field: "plate", headerName: "Plaka", width: 120 },
    {
      field: "isActive",
      headerName: "Aktif",
      width: 100,
      valueGetter: (params) => (params?.row?.isActive ? "Evet" : "Hayır"),
    },
    {
      field: "plateType",
      headerName: "Plaka Tipi",
      width: 150,
      valueGetter: (params) => plateTypes.find((type) => type?.value === params?.row?.plateType)?.label || "",
    },
    {
      field: "ownership",
      headerName: "Sahiplik",
      width: 150,
      valueGetter: (params) => ownerShipTypes.find((type) => type?.value === params?.row?.ownership)?.label || "",
    },
    { field: "department", headerName: "Departman", width: 200 },
    {
      field: "driver",
      headerName: "Sürücü",
      width: 250,
      valueGetter: (params) => {
        try {
          return driverOptions.find((item) => item?.value?.toString() === params?.row?.driver?.toString())?.label || "";
        } catch {
          return "";
        }
      },
    },

    {
      field: "generalInspectionEndDate",
      headerName: "Muayene Bitiş",
      width: 150,
      cellClassName: (params) =>
        shouldHighlightDate(params?.row?.generalInspectionEndDate) ? "hightlight-red" : undefined,
    },

    {
      field: "egzozInspectionEndDate",
      headerName: "Egzoz Bitiş",
      width: 150,
      cellClassName: (params) =>
        shouldHighlightDate(params?.row?.egzozInspectionEndDate) ? "hightlight-red" : undefined,
    },
    {
      field: "trafficPolicyEndDate",
      headerName: "Trafik Bitiş",
      width: 150,
      cellClassName: (params) =>
        shouldHighlightDate(params?.row?.trafficPolicyEndDate) ? "hightlight-red" : undefined,
    },
    {
      field: "insuranceEndDate",
      headerName: "Kasko Bitiş",
      width: 150,
      cellClassName: (params) => (shouldHighlightDate(params?.row?.insuranceEndDate) ? "hightlight-red" : undefined),
    },
    {
      field: "chairPolicyEndDate",
      headerName: "Koltuk Bitiş",
      width: 150,
      cellClassName: (params) => (shouldHighlightDate(params?.row?.chairPolicyEndDate) ? "hightlight-red" : undefined),
    },
    {
      field: "roadDocumentC1Date",
      headerName: "C1 Bitiş",
      width: 150,
      cellClassName: (params) => (shouldHighlightDate(params?.row?.roadDocumentC1Date) ? "hightlight-red" : undefined),
    },
    {
      field: "roadDocumentC2Date",
      headerName: "C2 Bitiş",
      width: 150,
      cellClassName: (params) => (shouldHighlightDate(params?.row?.roadDocumentC2Date) ? "hightlight-red" : undefined),
    },
    {
      field: "d2Date",
      headerName: "D2 Bitiş",
      width: 150,
      cellClassName: (params) => (shouldHighlightDate(params?.row?.d2Date) ? "hightlight-red" : undefined),
    },
    {
      field: "valilikDenetimKartiDate",
      headerName: "Valilik Bitiş",
      width: 150,
      cellClassName: (params) =>
        shouldHighlightDate(params?.row?.valilikDenetimKartiDate) ? "hightlight-red" : undefined,
    },
    {
      field: "tursabLevhasiDate",
      headerName: "Tursab Bitiş",
      width: 150,
      cellClassName: (params) => (shouldHighlightDate(params?.row?.tursabLevhasiDate) ? "hightlight-red" : undefined),
    },
    {
      field: "generalInspectionPhoto",
      headerName: "Muayene Dokümanı",
      width: 150,
      valueGetter: (params) => (params?.row?.generalInspectionPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.generalInspectionPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "egzozInspectionPhoto",
      headerName: "Egzoz Dokümanı",
      width: 150,
      valueGetter: (params) => (params?.row?.egzozInspectionPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.egzozInspectionPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "trafficPolicyPhoto",
      headerName: "Trafik Poliçesi",
      width: 150,
      valueGetter: (params) => (params?.row?.trafficPolicyPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.trafficPolicyPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "insurancePhoto",
      headerName: "Kasko Poliçesi",
      width: 150,
      valueGetter: (params) => (params?.row?.insurancePhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.insurancePhoto ? undefined : "hightlight-red"),
    },
    {
      field: "chairPolicyPhoto",
      headerName: "Koltuk Poliçesi",
      width: 150,
      valueGetter: (params) => (params?.row?.chairPolicyPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.chairPolicyPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "roadDocumentC1Photo",
      headerName: "C1 Belgesi",
      width: 150,
      valueGetter: (params) => (params?.row?.roadDocumentC1Photo ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.roadDocumentC1Photo ? undefined : "hightlight-red"),
    },
    {
      field: "roadDocumentC2Photo",
      headerName: "C2 Belgesi",
      width: 150,
      valueGetter: (params) => (params?.row?.roadDocumentC2Photo ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.roadDocumentC2Photo ? undefined : "hightlight-red"),
    },
    {
      field: "d2Photo",
      headerName: "D2 Belgesi",
      width: 150,
      valueGetter: (params) => (params?.row?.d2Photo ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.d2Photo ? undefined : "hightlight-red"),
    },
    {
      field: "valilikDenetimKartiPhoto",
      headerName: "Valilik Belgesi",
      width: 150,
      valueGetter: (params) => (params?.row?.valilikDenetimKartiPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.valilikDenetimKartiPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "tursabLevhasiPhoto",
      headerName: "Tursab Belgesi",
      width: 150,
      valueGetter: (params) => (params?.row?.tursabLevhasiPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.tursabLevhasiPhoto ? undefined : "hightlight-red"),
    },
    {
      field: "startPhoto",
      headerName: "Başlangıç Fotoğrafı",
      width: 150,
      valueGetter: (params) => (params?.row?.startPhoto ? "Evet" : "Hayır"),
      cellClassName: (params) => (params?.row?.startPhoto ? undefined : "hightlight-red"),
    },

    { field: "marka", headerName: "Marka", width: 150 },
    { field: "model", headerName: "Model", width: 150 },
    { field: "modelYear", headerName: "Model Yılı", width: 150 },
    { field: "formalCapacity", headerName: "Ruuhsat Kapasite", width: 150 },
    { field: "realCapacity", headerName: "Gerçek Kapasite", width: 150 },

    { field: "owner", headerName: "Sahibi", width: 150 },
    { field: "contact1", headerName: "İletişim", width: 150 },
    { field: "address", headerName: "Adres", width: 150 },
    { field: "yakitOwner", headerName: "Yakıt Sahibi", width: 150 },
    {
      field: "spaceKey",
      headerName: "Yedek Anahtar",
      width: 150,
      valueGetter: (params) => yesNoUnknowTypes.find((type) => type?.value === params?.row?.spaceKey)?.label || "",
    },
    { field: "ruhsatNo", headerName: "Ruhsat No", width: 150 },
    { field: "trackingSerialNo", headerName: "Takip No", width: 150 },
    { field: "uttsSerialNo", headerName: "UTTS No", width: 150 },
    { field: "chassisNo", headerName: "Şasi No", width: 150 },

    { field: "generalInspectionDate", headerName: "Muayene Tarihi", width: 150 },
    { field: "generalInspectionAmount", headerName: "Muayene Ücreti", width: 150 },
    { field: "egzozInspectionDate", headerName: "Egzoz Tarihi", width: 150 },
    { field: "egzozInspectionAmount", headerName: "Egzoz Ücreti", width: 150 },
    { field: "trafficPolicyStartDate", headerName: "Trafik Poliçe Başlangıç", width: 150 },
    { field: "trafficPolicyAmount", headerName: "Trafik Poliçe Ücreti", width: 150 },
    { field: "insuranceStartDate", headerName: "Kasko Başlangıç", width: 150 },
    { field: "insuranceAmount", headerName: "Kasko Ücreti", width: 150 },
    { field: "chairPolicyStartDate", headerName: "Koltuk Poliçe Başlangıç", width: 150 },
    { field: "chairPolicyAmount", headerName: "Koltuk Poliçe Ücreti", width: 150 },

    { field: "startDate", headerName: "Başlangıç Tarihi", width: 150 },
    { field: "startKm", headerName: "Başlangıç Km", width: 150 },
    {
      field: "endPhoto",
      headerName: "Çıkış Fotoğrafı",
      width: 150,
      valueGetter: (params) => (params?.row?.endPhoto ? "Evet" : "Hayır"),
    },
    { field: "endDate", headerName: "Çıkış Tarihi", width: 150 },
    { field: "endKm", headerName: "Çıkış Km", width: 150 },

    { field: "notes", headerName: "Notlar", width: 150 },

    // {
    //   field: "files",
    //   headerName: "Dosyalar",
    //   width: 200,
    //   valueGetter: (params) => params?.row?.folder?.id || "",
    // },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      showSelectedYear={false}
      isAlwaysFullScreen={true}
      hideCreateNewItem={false}
      hideRightHeader={false}
      SelectedItemComponent={InventoryVehicleItem}
      NewItemComponent={InventoryVehicleItem}
      DeleteItem={DeleteVehicle}
      InsertItem={InsertVehicle}
      UpdateItem={UpdateVehicle}
    />
  );
};

export default InventoryVehicles;
