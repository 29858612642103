import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "shared/components/Modal/ModalContext";
import ModalRoot from "shared/components/Modal/ModalRoot";

import "react-datepicker/dist/react-datepicker.css";
import "css/index.css";
import "css/flex.css";
import "css/card.css";
import "css/container.css";
import "components/Item.css";
import "components/List.css";
import App from "./App";
import "./i18n";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ModalProvider>
      {<App />}
      <ModalRoot />
    </ModalProvider>
  </BrowserRouter>
);
