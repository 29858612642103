import React, { useState, useContext } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";

import ThumbUp from "@mui/icons-material/ThumbUp";
import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import Students from "pages/Students";
import { AuthContext } from "shared/context/auth-context";

const ClientInfo = (props) => {
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);

  const { isLoading, sendRequest } = useHttpClient();
  const [reportCreated, setReportCreated] = useState(false);

  const [studentsWithLocationProblems, setStudentsWithLocationProblems] = useState([]);
  const [studentsWithoutNotifications, setStudentsWithoutNotifications] = useState([]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
    },
    false
  );

  // if(isLoading || !store.vehicleRoutes) //HERE
  if (isLoading && false) {
    //HERE
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={4}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              errorText="Kurum seçimini yapınız."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          <Grid item xs={3}>
            <div style={{ left: "10px", position: "relative", top: "15px" }}>
              <Button type="button" disabled={!formState.isValid}>
                Rapor Oluştur
              </Button>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div style={{ left: "10px", position: "relative", top: "15px" }}>
              Sadece aktif öğrencileriniz değerlendirmeye alınacaktır.
              <br />
            </div>
          </Grid>
        </Grid>
      </form>
      {reportCreated && (
        <div>
          <br />
          <div className="adminpageContainer">
            <div className="adminListContainer">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  {studentsWithLocationProblems.length > 0 && (
                    <div>
                      Konumu bulunmayan öğrenciler - <strong> {studentsWithLocationProblems.length} hata </strong>
                    </div>
                  )}

                  {studentsWithLocationProblems.length === 0 && (
                    <div style={{ display: "flex" }}>
                      <strong style={{ marginRight: "20px" }}>
                        {" "}
                        <ThumbUp />
                      </strong>{" "}
                      Tebrikler. Tüm öğrencilerinizin konumu işaretlenmiş durumda.
                    </div>
                  )}
                </AccordionSummary>
                {studentsWithLocationProblems.length > 0 && (
                  <AccordionDetails display="flex">
                    <Students students={studentsWithLocationProblems} />
                  </AccordionDetails>
                )}
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  {studentsWithoutNotifications.length > 0 && (
                    <div>
                      Bildirim eşleşmesi bulunmayan öğrenciler -{" "}
                      <strong> {studentsWithoutNotifications.length} hata </strong>
                    </div>
                  )}

                  {studentsWithoutNotifications.length === 0 && (
                    <div style={{ display: "flex" }}>
                      <strong style={{ marginRight: "20px" }}>
                        {" "}
                        <ThumbUp />
                      </strong>{" "}
                      Tebrikler. Tüm öğrencilerinizin bildirim eşleşmesi yapılmış.
                    </div>
                  )}
                </AccordionSummary>
                {studentsWithoutNotifications.length > 0 && (
                  <AccordionDetails display="flex">
                    {studentsWithoutNotifications.map((student, index) => (
                      <div key={index} style={{ width: "100%" }}>
                        {student.name}
                      </div>
                    ))}
                  </AccordionDetails>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClientInfo;
