import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useTranslation } from "react-i18next";
// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import FormFooter from "shared/components/FormElements/FormFooter";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";

const TransferRegionItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { schoolOptions, stopOptions, schools, transferregions } = useContext(StoreContext);
  const auth = useContext(AuthContext);

  // item info
  const itemType = "transferregion";
  const endPointUpdate = "transferregions";
  const endPointDelete = "transferregions";
  const endPointCreate = "transferregions";

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      name: { value: "", isValid: true },
      amount: { value: 0, isValid: true },
    },
    true
  );

  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentTransferRegion = transferregions.find((transferregion) => transferregion.id === itemId);
      setLoadedItem(currentTransferRegion);
    } catch (err) {}
    setIsItemLoaded(true);
  }, [transferregions, itemId]);

  // standard code
  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const infoToBeSent = {
        school: formState.inputs.school.value,
        name: formState.inputs.name.value.toString().trim(),
        amount: formState.inputs.amount.value,
      };

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Kurum"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Zorunlu alan"
            initialValue={
              schoolOptions.find((option) => option.value.toString() === loadedItem?.school.toString()) || null
            }
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label="İsim"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İsim giriniz."
            initialValue={loadedItem?.name || ""}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="amount"
            element="input"
            type="text"
            label="Ücret"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Ücret giriniz."
            initialValue={loadedItem?.amount || "0"}
            initialValid={true}
            onInput={inputHandler}
          />

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !formState.inputs.school.value || !formState.inputs.name.value || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default TransferRegionItem;
