import React, { useState, useContext } from "react";
import Button from "shared/components/FormElements/Button";
import Input from "shared/components/FormElements/Input";
import { CheckTimeString } from "shared/util/time";
import { StoreContext } from "shared/context/store-context";
import CustomSelect from "shared/components/FormElements/Select";
import { useForm } from "shared/hooks/form-hook";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ServiceItem from "./ServiceItem";
import { VALIDATOR_REQUIRE } from "shared/util/validators";

const Personel = (props) => {
  console.log("Service.js");
  const { driverOptions, drivers } = useContext(StoreContext);

  const [formState, inputHandler] = useForm(
    {
      name: { value: null, isValid: false },
      drivers: { value: null, isValid: true },
      plate: { value: null, isValid: true },
      starttime: { value: "00:01", isValid: true },
      stoptime: { value: "23:59", isValid: true },
      routeClientArrivalTime: {
        value: props.transfertime,
        isValid: true,
      },
    },
    false
  );

  const [plateKey, setPlateKey] = useState(null);

  const addNewService = async () => {
    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.starttime.value)) {
      window.toast.error("Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35");
      return;
    }

    props.addService(
      formState.inputs.name.value,
      formState.inputs.drivers.value,
      formState.inputs.plate.value,
      formState.inputs.starttime.value,
      formState.inputs.stoptime.value,
      formState.inputs.routeClientArrivalTime.value
    );
  };

  const driverChangeHandler = (selectedDrivers) => {
    if (selectedDrivers.length > 0) {
      const currentDriver = drivers.find((driver) => driver.id === selectedDrivers[0]);
      const currentPlate = currentDriver?.plate || currentDriver?.email || "-";
      inputHandler("plate", currentPlate, true);
      setPlateKey(currentPlate);
    } else {
      inputHandler("plate", null, false);
      setPlateKey(null);
    }
  };

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column", height: "100vh" }}>
      <div className="item-form" style={{ paddingBottom: "0px" }}>
        <div style={{ display: "flex", gap: "20px", justifyContent: "flex-start", marginTop: "10px", width: "100%" }}>
          <div style={{ width: "400px" }}>
            <Input
              id="name"
              element="input"
              type="text"
              label="İsim"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Zorunlu alan"
              onInput={inputHandler}
              dontShowError={true}
            />
          </div>

          <div style={{ width: "400px" }}>
            <CustomSelect
              options={driverOptions}
              id="drivers"
              isMulti={true}
              onInput={inputHandler}
              label="Service Yetkisi Olan Sürücüler"
              fireChange={driverChangeHandler}
            ></CustomSelect>
          </div>

          <div style={{ width: "200px" }}>
            <Input
              id="plate"
              key={plateKey}
              element="input"
              type="text"
              label="Araç Plakası"
              validators={[VALIDATOR_REQUIRE()]}
              initialValue={plateKey}
              initialValid={Boolean(plateKey)}
              errorText="Zorunlu alan"
              onInput={inputHandler}
              dontShowError={true}
            />
          </div>
          <div style={{ paddingTop: "30px" }}>
            <Button type="button" danger onClick={addNewService} disabled={!formState.isValid}>
              Servis Ekle
            </Button>
          </div>
        </div>

        <div style={{ borderBottom: "2px solid red", width: "100%", marginBottom: "20px", marginTop: "10px" }}></div>

        <TableContainer
          component={Paper}
          sx={{
            minWidth: 650,
            // marginTop: "20px",
            //paddingBottom: "50px",
            // marginBottom: "20px",
            // paddingTop: "20px",
            height: "100%",
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              width: "100%",
              justifyContent: "center",
              margin: "auto",
              zIndex: -1,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {props.services.map((service, index) => (
                <React.Fragment key={"FragmentList" + service.id}>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell colSpan={1}>
                      <div style={{ paddingTop: "27px", fontWeight: "bold", fontSize: "16px", width: "5px" }}>
                        {" "}
                        {index + 1}.
                      </div>
                    </TableCell>
                    <TableCell style={{ paddingBottom: "20px", paddingTop: "20px" }} colSpan={6}>
                      <ServiceItem
                        key={"ServiceItem" + service.id}
                        school={props.school}
                        service={service}
                        users={props.users.filter((user) => service.users.map((user) => user.id).includes(user.id))}
                        // deleteService={props.deleteService}
                        type={props.type}
                        // onDelete={() => RemoveService(service.id)}
                      />
                    </TableCell>
                  </TableRow>

                  {/* <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <b>Sürücü İletişim</b>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {service?.driver?.[0].name || "Sürücü seçilmemiş"} <br /> {service.driver?.[0].phonenumber}
                    </TableCell> */}

                  {/* <TableCell component="th" scope="row">
                      {currentSmses.filter((sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString())
                        .length > 0 ? (
                        <div>
                          {currentSmses
                            .filter((sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString())
                            .map((sms) => (
                              <div key={sms.id}>
                                {sms.text}
                                <br />
                                <i>{sms.date}</i>
                                <br />
                                <br />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div>Mesaj Yok</div>
                      )}
                    </TableCell> */}

                  {/* <TableCell scope="row" align="right" width="30%">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <textarea
                          style={{ margin: "16px" }}
                          key={"SMS" + vehicleRoute.driver[0].id}
                          id={"text" + vehicleRoute.driver[0].id.toString().trim()}
                          cols="30"
                          rows="7"
                          maxLength="155"
                          defaultValue={`Yeni servis operasyonu.\nServis: ${vehicleRoute.name}\nTarih: ${
                            loadedItem.transferdate
                          }\nSaat: ${GetStartStopTime(vehicleRoute.starttime)}\nOperasyon Tipi: ${
                            loadedItem.type === 100 ? "Toplama" : "Dağıtma"
                          }`}
                        ></textarea>
                        <div width="60px">
                          <Button type="button" danger onClick={() => SendSMSToDriver(vehicleRoute.driver[0])}>
                            SMS Gönder
                          </Button>
                        </div>
                      </div>
                    </TableCell> */}
                  {/* </TableRow> */}

                  {/* <TableRow>
                    <TableCell component="th" scope="row" colSpan={6}>
                
                      <div style={{ borderBottom: "2px solid red", width: "100%" }}></div>
                    </TableCell>
                  </TableRow> */}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Personel;
