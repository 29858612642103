import React, { useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";

import { useForm } from "shared/hooks/form-hook";
import { StoreContext } from "shared/context/store-context";

import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Marker from "shared/components/UIElements/Marker";
import { usePersonelServiceBackend } from "shared/hooks/backend/personel-service-hook";
import DeleteModal from "components/shared/DeleteModal";

import { VALIDATOR_REQUIRE, VALIDATOR_TIME_EMPTY_ACCEPTED } from "shared/util/validators";

const ServiceItem = (props) => {
  const { t } = useTranslation();
  const { driverOptions, UpdatePersonelService, DeletePersonelService, drivers, transferregionOptions } =
    useContext(StoreContext);

  const [plateKey, setPlateKey] = useState(null);

  const { updatePersonelService } = usePersonelServiceBackend();

  const [showMap, setShowMap] = useState(false);

  const ItemType = "personelservice";
  const endPointDelete = "personelservices";

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const [formStateItem, inputHandlerItem] = useForm(
    {
      name: { value: "", isValid: true },
      drivers: { value: [], isValid: true },
      plate: { value: props.service.plate, isValid: true },
      starttime: { value: "00:01", isValid: true },
      stoptime: { value: "23:59", isValid: true },
      transferregion: { value: props?.service?.transferregion, isValid: true },
      routeClientArrivalTime: { value: props.service.routeClientArrivalTime, isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      const infoToBeSent = {
        name: formStateItem.inputs.name.value,
        drivers: formStateItem.inputs.drivers.value,
        plate: formStateItem.inputs.plate.value,
        starttime: formStateItem.inputs.starttime.value,
        stoptime: formStateItem.inputs.stoptime.value,
        transferregion: formStateItem.inputs.transferregion.value,
        routeClientArrivalTime: formStateItem.inputs.routeClientArrivalTime.value,
      };

      const personelService = await updatePersonelService(props.service.id, infoToBeSent);

      if (personelService) {
        UpdatePersonelService(personelService);
        window.toast.success(t("updateSuccess"));
      }
    },
    [
      formStateItem.inputs.name.value,
      formStateItem.inputs.drivers.value,
      formStateItem.inputs.plate.value,
      formStateItem.inputs.starttime.value,
      formStateItem.inputs.stoptime.value,
      formStateItem.inputs.routeClientArrivalTime.value,
      updatePersonelService,
      props.service.id,
      UpdatePersonelService,
      t,
    ]
  );

  const toggleShowMap = () => {
    setShowMap(!showMap);
  };

  const driverChangeHandler = (selectedDrivers) => {
    if (selectedDrivers.length > 0) {
      const currentDriver = drivers.find((driver) => driver.id === selectedDrivers[0]);
      const currentPlate = currentDriver?.plate || currentDriver?.email || "-";
      inputHandlerItem("plate", currentPlate, true);
      setPlateKey(currentPlate);
    } else {
      inputHandlerItem("plate", null, false);
      setPlateKey(null);
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={ItemType}
        itemId={props.service.id}
        onDeleted={() => {
          DeletePersonelService(props.service.id);
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />
      <form key={props.service.id} onSubmit={itemUpdateSubmitHandler}>
        <div
          className="horizontal-flex"
          style={{ gap: "20px", justifyContent: "space-between", marginTop: "10px", width: "100%" }}
        >
          <Input
            id="name"
            element="input"
            type="text"
            label="Servis ismi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İsim giriniz."
            initialValue={props.service.name}
            initialValid={true}
            onInput={inputHandlerItem}
            dontShowError={true}
          />

          <CustomSelect
            options={driverOptions}
            id="drivers"
            isMulti={true}
            onInput={inputHandlerItem}
            label="Servise yetkisi olan sürücüler"
            initialValue={driverOptions.filter((option) =>
              props.service?.drivers?.map((driver) => driver.id).includes(option.value)
            )}
            initialValid={true}
            fireChange={driverChangeHandler}
          ></CustomSelect>

          <CustomSelect
            options={transferregionOptions}
            id="transferregion"
            onInput={inputHandlerItem}
            label={t("transferregion.title")}
            initialValue={
              transferregionOptions.find((option) => option.value === props.service?.transferregion) || null
            }
            initialValid={true}
          ></CustomSelect>

          <div className="horizontal-flex" style={{ width: "400px" }}>
            <Input
              id="routeClientArrivalTime"
              element="input"
              type="text"
              label="Servis Varış/Kalkış"
              validators={[VALIDATOR_TIME_EMPTY_ACCEPTED()]}
              errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
              onInput={inputHandlerItem}
              initialValue={props.service.routeClientArrivalTime || ""}
              initialValid={true}
              dontShowError={true}
            />

            <Input
              id="plate"
              key={plateKey}
              element="input"
              type="text"
              label="Plaka"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Zorunlu alan"
              onInput={inputHandlerItem}
              initialValue={plateKey}
              initialValid={Boolean(plateKey)}
              dontShowError={true}
            />
          </div>

          <div style={{ paddingTop: "27px" }}>
            <Button type="button" danger o onClick={toggleShowMap}>
              {showMap ? "Haritayı Gizle" : "Haritayı Göster"}
            </Button>
          </div>
          <div style={{ paddingTop: "27px" }}>
            <Button type="submit" disabled={!formStateItem.isValid}>
              Güncelle
            </Button>
          </div>

          <div style={{ paddingTop: "27px" }}>
            <Button type="button" danger o onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </div>

        {showMap && (
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                language: "tr",
                region: "tr",
                libraries: ["drawing"],
              }}
              options={function (maps) {
                return {
                  mapTypeControl: true,
                  mapTypeId: "roadmap",
                  mapTypeControlOptions: {
                    mapTypeIds: ["satellite", "roadmap"],
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: maps.ControlPosition.TOP_LEFT,
                  },
                };
              }}
              //defaultCenter={markerLocationMorning}
              center={
                props.users[0]
                  ? props.users[0].location
                  : { lat: props.school.location.lat, lng: props.school.location.lng }
              }
              defaultZoom={14}
            >
              {props.users.map((user) => {
                return (
                  <Marker
                    Icon={RoomIcon}
                    lat={user.location.lat}
                    lng={user.location.lng}
                    key={user.id}
                    // info={user.name}
                    info={
                      user.name +
                      " - " +
                      (props.service?.usersdetails?.find(
                        (userdetail) => userdetail?.user?.toString() === user?.id?.toString()
                      )?.transfertime || "")
                    }
                    initialShow={true}
                  />
                );
              })}
              <Marker
                Icon={SchoolIcon}
                lat={props.school.location.lat}
                lng={props.school.location.lng}
                info={props.school.name}
                alwaysShowMarker={true}
              />
            </GoogleMapReact>
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

export default ServiceItem;
