import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Layout from "./shared/Layout";
import ServiceItem from "../components/shift-personel-service/ShiftPersonelServiceItem";
import NewServiceItem from "../components/shift-personel-service/NewShiftPersonelServiceItem";
import { StoreContext } from "shared/context/store-context";
import { vehicleroutetypes } from "shared/util/types";
import { isPickUp } from "shared/util/util";

const StandardPersonelServices = () => {
  const { t } = useTranslation();

  const {
    shiftPersonelServices,
    schoolOptions,
    driverOptions,
    shiftOptions,
    DeletePersonelService,
    InsertPersonelService,
    UpdatePersonelService,
  } = useContext(StoreContext);
  const itemType = "shiftpersonelservice";
  const listName = "shiftpersonelservices";

  const listColumns = [
    {
      field: "school",
      headerName: "Kurum",
      width: 200,
      valueGetter: (params) =>
        schoolOptions.find((item) => item?.value?.toString() === params.row.school.id.toString())?.label,
    },
    {
      field: "shift",
      headerName: "Vardiya",
      width: 250,
      valueGetter: (params) => {
        try {
          return shiftOptions.find((item) => item.value.toString() === params.row.shift.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },
    {
      field: "type",
      headerName: "Türü",
      width: 100,
      valueGetter: (params) =>
        vehicleroutetypes.find((item) => item?.value?.toString() === params.row.type.toString())?.label,
    },
    { field: "name", headerName: "İsim", width: 400 },
    {
      field: "drivers",
      headerName: "Ana Sürücü",
      width: 300,
      valueGetter: (params) => {
        try {
          return driverOptions.find((item) => item.value.toString() === params?.row?.drivers[0]?.id.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },
    {
      field: "routeClientArrivalTime",
      headerName: "Varış/Kalkış Saati",
      width: 200,
    },

    { field: "plate", headerName: "Plaka", width: 100 },
    {
      field: "numOfPassengers",
      headerName: t("numberofpassengers"),
      width: 200,
      valueGetter: (params) => params.row?.users?.length?.toString() || "-",
    },
  ];

  return (
    <Layout
      itemType={itemType}
      items={shiftPersonelServices}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={ServiceItem}
      NewItemComponent={ServiceItem}
      DeleteItem={DeletePersonelService}
      InsertItem={InsertPersonelService}
      UpdateItem={UpdatePersonelService}
      isAlwaysFullScreen={true}
    />
  );
};

export default StandardPersonelServices;
