import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./types";

const Person = ({ id, name }) => {
    const [{ isDragging }, drag] = useDrag({
      item: { type: ItemTypes.PASSENGER, id, name },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      type: ItemTypes.PASSENGER
  
    });
  
    return (
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          backgroundColor: "#bbb",
          padding: "10px",
          margin: "5px",
          width: "100%",
          borderRadius: "5px"
        }}
      >
        {name}
      </div>
    );
  };

  export default Person;