import React, { useContext, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Card from "shared/components/UIElements/Card";
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import Link from "shared/components/FormElements/Link";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from "shared/util/validators";
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import CustomSelect from "shared/components/FormElements/Select";
import { clientTypes } from "shared/util/types";
import "./Auth.css";

const Auth = () => {
  let language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, sendRequest } = useHttpClient();
  const [loginFailed, setLoginFailed] = useState(false);
  const [failedMessage1, setFailedMessage1] = useState("Error");
  const [failedMessage2, setFailedMessage2] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      serviceType: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const onHandleInput = (event) => {
    setLoginFailed(false);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/clients/signup`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          email: formState.inputs.email.value,
          initpassword: formState.inputs.password.value,
          serviceType: formState.inputs.serviceType.value,
          language: language,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.userId, responseData.token, null, responseData.userName);
      navigate("/users");
    } catch (err) {
      const firstDotIndex = err?.message?.indexOf(".");
      if (firstDotIndex !== -1 && err.code === 403) {
        setFailedMessage1(err?.message?.slice(0, firstDotIndex + 1));
        setFailedMessage2(err?.message?.slice(firstDotIndex + 1).trim());
      } else {
        setFailedMessage1(err?.message);
        setFailedMessage2("");
      }
      document.getElementById("email").focus();
      setLoginFailed(true);
    }
  };

  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      <Card className="authentication" style={{ minWidth: "500px" }}>
        <h2>{t("userSignup")}</h2>
        <hr />
        <form onSubmit={authSubmitHandler}>
          <Input
            element="input"
            id="name"
            type="name"
            label={t("name")}
            validators={[VALIDATOR_REQUIRE()]}
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading}
            dontShowError={true}
          />
          <Input
            element="input"
            id="email"
            type="email"
            label={t("email")}
            validators={[VALIDATOR_EMAIL()]}
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading}
            dontShowError={true}
          />
          <Input
            element="input"
            id="password"
            type="password"
            label={t("password")}
            validators={[VALIDATOR_MINLENGTH(6)]}
            //errorText="En az 6 karakterden oluşan şifrenizi giriniz."
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading}
            autoComplete={"current-password"}
            dontShowError={true}
          />

          <CustomSelect
            options={clientTypes(t)}
            id="serviceType"
            onInput={inputHandler}
            label={t("serviceType")}
            initialValid={false}
          ></CustomSelect>

          <div style={{ textAlign: "left", marginBottom: "1rem", marginTop: "2rem" }}>
            <input
              type="checkbox"
              id="terms"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <label htmlFor="terms">
              {language === "tr" && (
                <React.Fragment>
                  <a href="/terms" target="_blank" rel="noopener noreferrer">
                    {t("acceptTerms.termsOfService")}
                  </a>
                  {t("acceptTerms.iAcceptThe")}
                </React.Fragment>
              )}
              {language === "en" && (
                <React.Fragment>
                  {t("acceptTerms.iAcceptThe")}
                  <Link to="/terms">{t("acceptTerms.termsOfService")}.</Link>
                </React.Fragment>
              )}
            </label>
          </div>

          <div style={{ textAlign: "left", marginBottom: "2rem" }}>
            <input
              type="checkbox"
              id="privacy"
              checked={privacyAccepted}
              onChange={() => setPrivacyAccepted(!privacyAccepted)}
            />
            <label htmlFor="privacy">
              {language === "tr" && (
                <React.Fragment>
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    {t("acceptPrivacy.privacyPolicy")}
                  </a>
                  {t("acceptPrivacy.iAcceptThe")}
                </React.Fragment>
              )}
              {language === "en" && (
                <React.Fragment>
                  {t("acceptPrivacy.iAcceptThe")}
                  <Link to="/privacy">{t("acceptPrivacy.privacyPolicy")}.</Link>
                </React.Fragment>
              )}
            </label>
          </div>

          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!formState.isValid || isLoading || !termsAccepted || !privacyAccepted}
          >
            {t("submit")}
          </Button>
          <Link to="/login" styles={{ float: "left", fontWeight: "bold" }}>
            {t("userLogin")}
          </Link>
          <Link to="/recover-password" styles={{ float: "right", fontWeight: "bold" }}>
            {t("resetPassword")}
          </Link>
          {loginFailed && (
            <div className="login-failed">
              <p>{failedMessage1}</p>
              <p>{failedMessage2}</p>
            </div>
          )}
        </form>
      </Card>
    </React.Fragment>
  );
};

export default Auth;
