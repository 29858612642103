import { CheckTimeString } from "../util/time";

const VALIDATOR_TYPE_REQUIRE = "REQUIRE";
const VALIDATOR_TYPE_MINLENGTH = "MINLENGTH";
const VALIDATOR_TYPE_MAXLENGTH = "MAXLENGTH";
const VALIDATOR_TYPE_MIN = "MIN";
const VALIDATOR_TYPE_MAX = "MAX";
const VALIDATOR_TYPE_EMAIL = "EMAIL";
const VALIDATOR_TYPE_FILE = "FILE";
const VALIDATOR_TYPE_PHONENUMBER = "PHONENUMBER";
const VALIDATOR_TYPE_NONE = "NONE";
const VALIDATOR_TYPE_TIME = "TIME";
const VALIDATOR_TYPE_TIME_EMPTY_ACCEPTED = "TIME_EMPTY_ACCEPTED";

export const VALIDATOR_REQUIRE = () => ({ type: VALIDATOR_TYPE_REQUIRE });
export const VALIDATOR_NONE = () => ({ type: VALIDATOR_TYPE_NONE });
export const VALIDATOR_FILE = () => ({ type: VALIDATOR_TYPE_FILE });
export const VALIDATOR_MINLENGTH = (val) => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val,
});
export const VALIDATOR_MAXLENGTH = (val) => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val,
});
export const VALIDATOR_MIN = (val) => ({ type: VALIDATOR_TYPE_MIN, val: val });
export const VALIDATOR_MAX = (val) => ({ type: VALIDATOR_TYPE_MAX, val: val });
export const VALIDATOR_EMAIL = () => ({ type: VALIDATOR_TYPE_EMAIL });
export const VALIDATOR_PHONENUMBER = (val) => ({ type: VALIDATOR_TYPE_PHONENUMBER, val: val });
export const VALIDATOR_TIME = (val) => ({ type: VALIDATOR_TYPE_TIME, val: val });
export const VALIDATOR_TIME_EMPTY_ACCEPTED = (val) => ({ type: VALIDATOR_TYPE_TIME_EMPTY_ACCEPTED, val: val });

export const validate = (value, validators) => {
  let isValid = true;
  for (const validator of validators) {
    if (validator.type === VALIDATOR_TYPE_NONE) {
      isValid = true;
    }
    if (validator.type === VALIDATOR_TYPE_REQUIRE) {
      isValid = isValid && value.trim().length > 0;
    }
    if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
      isValid = isValid && value.trim().length >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
      isValid = isValid && value.trim().length <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MIN) {
      isValid = isValid && +value >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAX) {
      isValid = isValid && +value <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_EMAIL) {
      isValid = isValid && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_PHONENUMBER) {
      isValid = isValid && value.trim().length === validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_TIME) {
      isValid = isValid && CheckTimeString(value);
    }
    if (validator.type === VALIDATOR_TYPE_TIME_EMPTY_ACCEPTED) {
      if (!value || value.trim().length === 0) {
        isValid = true;
      } else {
        isValid = isValid && CheckTimeString(value);
      }
    }
  }
  return isValid;
};
