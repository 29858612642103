import React, { useRef, useState, useEffect } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import { useTranslation } from "react-i18next";

import Button from "./Button";
import "./FileUpload.css";

const FileUpload = (props) => {
  const [file, setFile] = useState();
  const { t } = useTranslation();
  //const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      //  setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const downloadExampleFile = async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/files/${props.exampleFileName}`,
        "GET",
        null,
        {},
        false,
        true
      );

      const url = window.URL.createObjectURL(response);

      // <a> elementi ile indirme işlemi başlat
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", props.exampleFileName); // İndirilen dosya adı
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Belleği temizle
    } catch (err) {}
  };

  return (
    <div className="horizontal-flex" style={{ width: "auto", columnGap: "0px", alignItems: "center" }}>
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={pickedHandler}
      />

      <Button type="button" disabled={props.disabled} onClick={pickImageHandler}>
        {t("uploadFile")}
      </Button>

      {props.exampleFileName && (
        <button
          type="button"
          onClick={downloadExampleFile}
          style={{
            marginLeft: "10px",
            color: "blue",
            background: "none",
            border: "none",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {t("downloadExampleFile")}
        </button>
      )}
      {/* </div> */}
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default FileUpload;
