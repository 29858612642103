import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";

import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import CheckBox from "shared/components/FormElements/CheckBox";
import NotFound from "shared/components/UIElements/NotFound";

import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { isActiveOptions, ownerShipTypes, yesNoUnknowTypes, plateTypes } from "shared/util/types";
import DatePicker from "shared/components/FormElements/DatePicker";

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";

const InventoryVehicleItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { vehicles, driverOptions } = useContext(StoreContext);

  const itemType = "vehicle";
  const endPointUpdate = "vehicles";
  const endPointDelete = "vehicles";
  const endPointCreate = "vehicles";

  // Initialize form with all fields from the schema
  const [formState, inputHandler, setFormData] = useForm(
    {
      isActive: { value: "", isValid: true },
      plate: { value: null, isValid: false },
      ownership: { value: "", isValid: true },
      owner: { value: "", isValid: true },
      contact1: { value: "", isValid: true },
      address: { value: "", isValid: true },
      startDate: { value: "", isValid: true },
      startKm: { value: "", isValid: true },
      driver: { value: null, isValid: true },
      startPhoto: { value: false, isValid: true },
      endDate: { value: "", isValid: true },
      endKm: { value: "", isValid: true },
      endPhoto: { value: "", isValid: true },
      notes: { value: "", isValid: true },
      department: { value: "", isValid: true },
      spareKey: { value: "", isValid: true },
      trackingSerialNo: { value: "", isValid: true },
      uttsSerialNo: { value: "", isValid: true },
      plateType: { value: "", isValid: true },
      chassisNo: { value: "", isValid: true },
      modelYear: { value: "", isValid: true },
      marka: { value: "", isValid: true },
      model: { value: "", isValid: true },
      formalCapacity: { value: "", isValid: true },
      realCapacity: { value: "", isValid: true },
      generalInspectionDate: { value: "", isValid: true },
      generalInspectionAmount: { value: "", isValid: true },
      egzozInspectionDate: { value: "", isValid: true },
      egzozInspectionAmount: { value: "", isValid: true },
      trafficPolicyStartDate: { value: "", isValid: true },
      trafficPolicyEndDate: { value: "", isValid: true },
      trafficPolicyAmount: { value: "", isValid: true },
      insuranceStartDate: { value: "", isValid: true },
      insuranceEndDate: { value: "", isValid: true },
      insuranceAmount: { value: "", isValid: true },
      chairPolicyStartDate: { value: "", isValid: true },
      chairPolicyEndDate: { value: "", isValid: true },
      chairPolicyAmount: { value: "", isValid: true },
      roadDocumentC1Date: { value: "", isValid: true },
      roadDocumentC1Photo: { value: false, isValid: true },
      roadDocumentC2Date: { value: "", isValid: true },
      roadDocumentC2Photo: { value: false, isValid: true },
      d2Date: { value: "", isValid: true },
      d2Photo: { value: false, isValid: true },
      valilikDenetimKartiDate: { value: "", isValid: true },
      valilikDenetimKartiPhoto: { value: false, isValid: true },
      tursabLevhasiDate: { value: "", isValid: true },
      tursabLevhasiPhoto: { value: false, isValid: true },
      yakitOwner: { value: "", isValid: true },
      generalInspectionPhoto: { value: false, isValid: true },
      egzozInspectionPhoto: { value: false, isValid: true },
      trafficPolicyPhoto: { value: false, isValid: true },
      generalInspectionEndDate: { value: "", isValid: true },
      egzozInspectionEndDate: { value: "", isValid: true },
      chairPolicyPhoto: { value: false, isValid: true },
      ruhsatNo: { value: "", isValid: true },
      insurancePhoto: { value: false, isValid: true },
    },
    false
  );

  // Fetching item data
  useEffect(() => {
    const fetchItem = async () => {
      if (!isNewItem) {
        setIsItemLoaded(false);
        try {
          const currentVehicle = vehicles.find((vehicle) => vehicle.id === itemId);
          if (currentVehicle) {
            // Populate form with existing data
            setLoadedItem(currentVehicle);
          }
        } catch (err) {
          console.log(err);
        }
        setIsItemLoaded(true);
      } else {
        // New item
        setIsItemLoaded(true);
      }
    };
    fetchItem();
  }, [itemId, vehicles, isNewItem, setFormData]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();
    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    // Build the object to send (spelled out)
    const infoToBeSent = {
      isActive: formState.inputs.isActive.value,
      plate: formState.inputs.plate.value,
      ownership: formState.inputs.ownership.value,
      owner: formState.inputs.owner.value,
      contact1: formState.inputs.contact1.value,
      address: formState.inputs.address.value,
      startDate: formState.inputs.startDate.value,
      startKm: formState.inputs.startKm.value,
      startPhoto: formState.inputs.startPhoto.value,
      endDate: formState.inputs.endDate.value,
      driver: formState.inputs.driver.value,
      endKm: formState.inputs.endKm.value,
      endPhoto: formState.inputs.endPhoto.value,
      notes: formState.inputs.notes.value,
      department: formState.inputs.department.value,
      spareKey: formState.inputs.spareKey.value,
      trackingSerialNo: formState.inputs.trackingSerialNo.value,
      uttsSerialNo: formState.inputs.uttsSerialNo.value,
      plateType: formState.inputs.plateType.value,
      chassisNo: formState.inputs.chassisNo.value,
      modelYear: formState.inputs.modelYear.value,
      marka: formState.inputs.marka.value,
      model: formState.inputs.model.value,
      formalCapacity: formState.inputs.formalCapacity.value,
      realCapacity: formState.inputs.realCapacity.value,
      generalInspectionDate: formState.inputs.generalInspectionDate.value,
      generalInspectionAmount: formState.inputs.generalInspectionAmount.value,
      egzozInspectionDate: formState.inputs.egzozInspectionDate.value,
      egzozInspectionAmount: formState.inputs.egzozInspectionAmount.value,
      trafficPolicyStartDate: formState.inputs.trafficPolicyStartDate.value,
      trafficPolicyEndDate: formState.inputs.trafficPolicyEndDate.value,
      trafficPolicyAmount: formState.inputs.trafficPolicyAmount.value,
      insuranceStartDate: formState.inputs.insuranceStartDate.value,
      insuranceEndDate: formState.inputs.insuranceEndDate.value,
      insuranceAmount: formState.inputs.insuranceAmount.value,
      chairPolicyStartDate: formState.inputs.chairPolicyStartDate.value,
      chairPolicyEndDate: formState.inputs.chairPolicyEndDate.value,
      chairPolicyAmount: formState.inputs.chairPolicyAmount.value,
      roadDocumentC1Date: formState.inputs.roadDocumentC1Date.value,
      roadDocumentC1Photo: formState.inputs.roadDocumentC1Photo.value,
      roadDocumentC2Date: formState.inputs.roadDocumentC2Date.value,
      roadDocumentC2Photo: formState.inputs.roadDocumentC2Photo.value,
      d2Date: formState.inputs.d2Date.value,
      d2Photo: formState.inputs.d2Photo.value,
      valilikDenetimKartiDate: formState.inputs.valilikDenetimKartiDate.value,
      valilikDenetimKartiPhoto: formState.inputs.valilikDenetimKartiPhoto.value,
      tursabLevhasiDate: formState.inputs.tursabLevhasiDate.value,
      tursabLevhasiPhoto: formState.inputs.tursabLevhasiPhoto.value,
      yakitOwner: formState.inputs.yakitOwner.value,
      generalInspectionPhoto: formState.inputs.generalInspectionPhoto.value,
      egzozInspectionPhoto: formState.inputs.egzozInspectionPhoto.value,
      trafficPolicyPhoto: formState.inputs.trafficPolicyPhoto.value,
      generalInspectionEndDate: formState.inputs.generalInspectionEndDate.value,
      egzozInspectionEndDate: formState.inputs.egzozInspectionEndDate.value,
      chairPolicyPhoto: formState.inputs.chairPolicyPhoto.value,
      ruhsatNo: formState.inputs.ruhsatNo.value,
      insurancePhoto: formState.inputs.insurancePhoto.value,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      window.toast.error(t("httpCodes." + err.code));
    }
  };

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  if (!isItemLoaded && !isNewItem) {
    return <p>Loading...</p>;
  }

  if (!isNewItem && isItemLoaded && !loadedItem && !error) {
    return <NotFound item={itemType} />;
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          {/* <a href={`https://drive.google.com/drive/folders/${loadedItem?.folder?.id}`} target="_blank" rel="noreferrer">
            Dosyalar
          </a> */}
          <div className="horizontal-flex">
            <CustomSelect
              options={isActiveOptions}
              id="isActive"
              onInput={inputHandler}
              label="Çalışma Durumu"
              initialValue={
                isActiveOptions.find((option) => option.value.toString() === loadedItem?.isActive.toString())
                  ? isActiveOptions.find((option) => option.value.toString() === loadedItem?.isActive.toString())
                  : isActiveOptions[1]
              }
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>

            <Input
              id="plate"
              element="input"
              type="text"
              label="Plaka"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter Plate"
              initialValue={loadedItem?.plate || ""}
              initialValid={true}
              onInput={inputHandler}
            />

            <CustomSelect
              options={plateTypes}
              id="plateType"
              onInput={inputHandler}
              label="Plaka Türü"
              initialValue={
                plateTypes.find((option) => option.value.toString() === loadedItem?.plateType?.toString())
                  ? plateTypes.find((option) => option.value.toString() === loadedItem?.plateType?.toString())
                  : plateTypes[0]
              }
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
            ></CustomSelect>

            <Input
              id="department"
              element="input"
              type="text"
              label="Departman"
              validators={[VALIDATOR_NONE()]}
              initialValue={loadedItem?.department || ""}
              initialValid={true}
              onInput={inputHandler}
            />

            <div style={{ flex: 2 }}>
              <CustomSelect
                options={driverOptions}
                id="driver"
                isMulti={false}
                onInput={inputHandler}
                label={t("driver")}
                initialValue={driverOptions.find((option) => option.value === loadedItem?.driver) || null}
                initialValid={true}
              ></CustomSelect>
            </div>
          </div>

          <div className="horizontal-flex" style={{ maxWidth: "33%", marginLeft: "0px" }}>
            <Input
              id="ruhsatNo"
              element="input"
              type="text"
              label="Ruhsat No"
              validators={[VALIDATOR_NONE()]}
              initialValue={loadedItem?.ruhsatNo || ""}
              initialValid={true}
              onInput={inputHandler}
            />

            <Input
              id="yakitOwner"
              element="input"
              type="text"
              label="Yakıt Kime Ait"
              validators={[VALIDATOR_NONE()]}
              initialValue={loadedItem?.yakitOwner || ""}
              initialValid={true}
              onInput={inputHandler}
            />
          </div>
          {!isNewItem && (
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <div className="horizontal-flex" style={{ alignItems: "center", justifyContent: "flex-start" }}>
                <div>
                  <Button type="button" href={`https://drive.google.com/drive/folders/${loadedItem?.folder?.id}`}>
                    Dosyalara Git
                  </Button>
                </div>
              </div>
            </div>
          )}

          <Input
            id="notes"
            element="textarea"
            type="text"
            label="Notlar"
            rows={7}
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.notes || ""}
            initialValid={true}
            onInput={inputHandler}
          />

          <div className="horizontal-flex">
            <div className="vertical-flex red-group-container" style={{ paddingTop: "15px", paddingBottom: "10px" }}>
              <CustomSelect
                options={ownerShipTypes}
                id="ownership"
                onInput={inputHandler}
                label="Kiralama Şekli"
                initialValue={
                  ownerShipTypes.find((option) => option.value.toString() === loadedItem?.ownership?.toString())
                    ? ownerShipTypes.find((option) => option.value.toString() === loadedItem?.ownership?.toString())
                    : ownerShipTypes[0]
                }
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              ></CustomSelect>

              {/* <Input
                id="approvingPerson"
                element="input"
                type="text"
                label="Onaylayan"
                validators={[VALIDATOR_NONE()]}
                initialValue={formState.inputs.approvingPerson.value}
                initialValid={true}
                onInput={inputHandler}
              /> */}

              <Input
                id="owner"
                element="input"
                type="text"
                label="Araç Sahibi"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.owner || ""}
                initialValid={true}
                onInput={inputHandler}
              />
              <Input
                id="contact1"
                element="input"
                type="text"
                label="Araç Sahibi İletişim"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.contact1 || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <Input
                id="address"
                element="input"
                type="text"
                label="Araç Sahibi Adres"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.address || ""}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>

            <div className="vertical-flex">
              <div className="vertical-flex  red-group-container">
                <div className="horizontal-flex" style={{ alignItems: "center" }}>
                  <Input
                    id="modelYear"
                    element="input"
                    type="text"
                    label="Model Yılı"
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.modelYear || ""}
                    initialValid={true}
                    onInput={inputHandler}
                  />

                  <Input
                    id="marka"
                    element="input"
                    type="text"
                    label="Marka"
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.marka || ""}
                    initialValid={true}
                    onInput={inputHandler}
                  />

                  <Input
                    id="model"
                    element="input"
                    type="text"
                    label="Ticari Adı"
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.model || ""}
                    initialValid={true}
                    onInput={inputHandler}
                  />
                </div>
              </div>
              <div className="vertical-flex  red-group-container">
                <div className="horizontal-flex" style={{ alignItems: "center" }}>
                  <DatePicker
                    id="startDate"
                    onInput={inputHandler}
                    label="Giriş Tarihi"
                    initialValid={true}
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.startDate?.toString() || ""}
                  ></DatePicker>

                  <Input
                    id="startKm"
                    element="input"
                    type="text"
                    label="Giriş Km"
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.startKm || ""}
                    initialValid={true}
                    onInput={inputHandler}
                  />

                  <CheckBox
                    id="startPhoto"
                    label="Giriş Fotoğrafları Yüklendi"
                    initialValue={loadedItem?.startPhoto}
                    initialValid={true}
                    onInput={inputHandler}
                    validators={[VALIDATOR_NONE()]}
                  />
                </div>
              </div>

              <div className="vertical-flex  red-group-container">
                <div className="horizontal-flex" style={{ alignItems: "center" }}>
                  <DatePicker
                    id="endDate"
                    onInput={inputHandler}
                    label="Çıkış Tarihi"
                    initialValid={true}
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.endDate?.toString() || ""}
                  ></DatePicker>

                  <Input
                    id="endKm"
                    element="input"
                    type="text"
                    label="Çıkış Km"
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.endKm || ""}
                    initialValid={true}
                    onInput={inputHandler}
                  />

                  <CheckBox
                    id="endPhoto"
                    label="Çıkış Fotoğrafları Yüklendi"
                    initialValue={loadedItem?.endPhoto}
                    initialValid={true}
                    onInput={inputHandler}
                    validators={[VALIDATOR_NONE()]}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <Input
            id="endReason"
            element="input"
            type="text"
            label="Çıkış Nedeni"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.endReason || ""}
            initialValid={true}
            onInput={inputHandler}
          /> */}

          <div className="horizontal-flex">
            <div className="vertical-flex red-group-container">
              <DatePicker
                id="generalInspectionDate"
                onInput={inputHandler}
                label="Genel Muayene Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.generalInspectionDate?.toString() || ""}
              ></DatePicker>

              <DatePicker
                id="generalInspectionEndDate"
                onInput={inputHandler}
                label="Genel Muayene Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.generalInspectionEndDate?.toString() || ""}
              ></DatePicker>

              <Input
                id="generalInspectionAmount"
                element="input"
                type="text"
                label="Genel Muayene Ücreti"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.generalInspectionAmount || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <CheckBox
                id="generalInspectionPhoto"
                label="Genel Muayene Dosyası Yüklendi"
                initialValue={loadedItem?.generalInspectionPhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="egzozInspectionDate"
                onInput={inputHandler}
                label="Egzoz Muayene Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.egzozInspectionDate?.toString() || ""}
              ></DatePicker>

              <DatePicker
                id="egzozInspectionEndDate"
                onInput={inputHandler}
                label="Egzoz Muayene Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.egzozInspectionEndDate?.toString() || ""}
              ></DatePicker>

              <Input
                id="egzozInspectionAmount"
                element="input"
                type="text"
                label="Egzoz Muayene Ücreti"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.egzozInspectionAmount || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <CheckBox
                id="egzozInspectionPhoto"
                label="Egzoz Muayene Dosyası Yüklendi"
                initialValue={loadedItem?.egzozInspectionPhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="trafficPolicyStartDate"
                onInput={inputHandler}
                label="Trafik Poliçesi Başlangıç Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.trafficPolicyStartDate?.toString() || ""}
              ></DatePicker>

              <DatePicker
                id="trafficPolicyEndDate"
                onInput={inputHandler}
                label="Trafik Poliçesi Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.trafficPolicyEndDate?.toString() || ""}
              ></DatePicker>

              <Input
                id="trafficPolicyAmount"
                element="input"
                type="text"
                label="Trafik Poliçesi Ücreti"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.trafficPolicyAmount || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <CheckBox
                id="trafficPolicyPhoto"
                label="Trafik Poliçesi Dosyası Yüklendi"
                initialValue={loadedItem?.trafficPolicyPhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>
            <div className="vertical-flex red-group-container">
              <DatePicker
                id="insuranceStartDate"
                onInput={inputHandler}
                label="Kasko Başlangıç Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.insuranceStartDate?.toString() || ""}
              ></DatePicker>

              <DatePicker
                id="insuranceEndDate"
                onInput={inputHandler}
                label="Kasko Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.insuranceEndDate?.toString() || ""}
              ></DatePicker>

              <Input
                id="insuranceAmount"
                element="input"
                type="text"
                label="Kasko Ücreti"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.insuranceAmount || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <CheckBox
                id="insurancePhoto"
                label="Kasko Dosyası Yüklendi"
                initialValue={loadedItem?.insurancePhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="chairPolicyStartDate"
                onInput={inputHandler}
                label="Koltuk Poliçesi Başlangıç Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.chairPolicyStartDate?.toString() || ""}
              ></DatePicker>

              <DatePicker
                id="chairPolicyEndDate"
                onInput={inputHandler}
                label="Koltuk Poliçesi Bitiş Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.chairPolicyEndDate?.toString() || ""}
              ></DatePicker>

              <Input
                id="chairPolicyAmount"
                element="input"
                type="text"
                label="Koltuk Poliçesi Ücreti"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.chairPolicyAmount || ""}
                initialValid={true}
                onInput={inputHandler}
              />

              <CheckBox
                id="chairPolicyPhoto"
                label="Koltuk Poliçesi Dosyası Yüklendi"
                initialValue={loadedItem?.chairPolicyPhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>
          </div>

          <div className="horizontal-flex"></div>

          <div className="horizontal-flex">
            <div className="vertical-flex red-group-container">
              <DatePicker
                id="roadDocumentC1Date"
                onInput={inputHandler}
                label="Yol Belgesi C1 Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.roadDocumentC1Date?.toString() || ""}
              ></DatePicker>

              <CheckBox
                id="roadDocumentC1Photo"
                label="Yol Belgesi C1 Dosyası Yüklendi"
                initialValue={loadedItem?.roadDocumentC1Photo}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>
            <div className="vertical-flex red-group-container">
              <DatePicker
                id="roadDocumentC2Date"
                onInput={inputHandler}
                label="Yol Belgesi C2 Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.roadDocumentC2Date?.toString() || ""}
              ></DatePicker>

              <CheckBox
                id="roadDocumentC2Photo"
                label="Yol Belgesi C2 Dosyası Yüklendi"
                initialValue={loadedItem?.roadDocumentC2Photo}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="d2Date"
                onInput={inputHandler}
                label="D2 Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.d2Date?.toString() || ""}
              ></DatePicker>

              <CheckBox
                id="d2Photo"
                label="D2 Dosyası Yüklendi"
                initialValue={loadedItem?.d2Photo}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="valilikDenetimKartiDate"
                onInput={inputHandler}
                label="Valilik Denetim Kartı Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.valilikDenetimKartiDate?.toString() || ""}
              ></DatePicker>

              <CheckBox
                id="valilikDenetimKartiPhoto"
                label="Valilik Denetim Dosyası Yüklendi"
                initialValue={loadedItem?.valilikDenetimKartiPhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <DatePicker
                id="tursabLevhasiDate"
                onInput={inputHandler}
                label="Tursab Levhası Tarihi"
                initialValid={true}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.tursabLevhasiDate?.toString() || ""}
              ></DatePicker>

              <CheckBox
                id="tursabLevhasiPhoto"
                label="Tursab Levhası Dosyası Yüklendi"
                initialValue={loadedItem?.tursabLevhasiPhoto}
                initialValid={true}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
              />
            </div>
          </div>

          <div className="horizontal-flex">
            <div className="vertical-flex red-group-container">
              <Input
                id="trackingSerialNo"
                element="input"
                type="text"
                label="Araç Takip Seri No"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.trackingSerialNo || ""}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <CustomSelect
                options={yesNoUnknowTypes}
                id="spareKey"
                onInput={inputHandler}
                label="Yedek Anahtar"
                initialValue={
                  yesNoUnknowTypes.find((option) => option.value.toString() === loadedItem?.spareKey?.toString())
                    ? yesNoUnknowTypes.find((option) => option.value.toString() === loadedItem?.spareKey?.toString())
                    : yesNoUnknowTypes[0]
                }
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              ></CustomSelect>
            </div>

            <div className="vertical-flex red-group-container">
              <Input
                id="formalCapacity"
                element="input"
                type="text"
                label="Ruhsat Koltuk Kapasitesi"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.formalCapacity || ""}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>

            <div className="vertical-flex red-group-container">
              <Input
                id="realCapacity"
                element="input"
                type="text"
                label="Reel Koltuk Kapasitesi"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.realCapacity}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>
          </div>

          <div className="horizontal-flex" style={{ width: "49.5%", justifyContent: "flex-start", float: "left" }}>
            <div className="vertical-flex red-group-container">
              <Input
                id="uttsSerialNo"
                element="input"
                type="text"
                label="UTTS Seri No"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.uttsSerialNo || ""}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>
            <div className="vertical-flex red-group-container">
              <Input
                id="chassisNo"
                element="input"
                type="text"
                label="Şasi No - EGM No"
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem?.chassisNo || ""}
                initialValid={true}
                onInput={inputHandler}
              />
            </div>
          </div>

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !formState.inputs.plate.value || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default InventoryVehicleItem;
