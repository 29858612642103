/* eslint-disable react/jsx-key */
/*eslint-disable eqeqeq*/
import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import { useTranslation } from "react-i18next";

import DirectionsBus from "@mui/icons-material/DirectionsBus";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { useForm } from "shared/hooks/form-hook";

// #region Components
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Marker from "shared/components/UIElements/Marker";
import Player from "shared/components/UIElements/player";
import { PlayerStates } from "shared/util/types";
import { isPickUp } from "shared/util/util";
import CustomSelect from "shared/components/FormElements/Select";

import {
  TRAVEL_ACTION_TYPE,
  TRAVEL_ACTION_TYPE_EXPLANATIONS,
  APPROACH_TO_HOME_TYPE_EXPLANATIONS,
  STUDENT_GETON_INFO_TYPE_EXPLANATIONS,
  ARRIVED_SCHOOL_TYPE_EXPLANATIONS,
  ARRIVED_SCHOOL_TYPE,
  LEAVED_SCHOOL_TYPE,
  TRAVEL_LEAVED_TYPE_EXPLANATIONS,
} from "shared/util/types";

// #endregion Components

// #region Hooks
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./LiveItem.css";

const LiveItem = ({ itemId, onClose }) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [currentStudents, setCurrentStudents] = useState([]);

  const { users, userMap, studentToParentMap, vehicleRoutes, relations, schools, schoolOptions } =
    useContext(StoreContext);

  // eslint-disable-next-line
  // const [itemstate, setItemState] = useState();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [travels, setTravels] = useState([]);
  const travelRef = useRef(null);
  const [travelOptions, setTravelOptions] = useState([]);
  const [travelLocations, setTravelLocations] = useState([]);
  const [travelActions, setTravelActions] = useState([]);

  const locationsREF = useRef([]);

  const mapREF = useRef(null);
  const mapsREF = useRef(null);
  const [school, setSchool] = useState();

  const [index, setIndex] = useState(0);

  const indexREF = useRef(0);
  const playerStateREF = useRef(PlayerStates.NOTSTARTED);

  const [formState, inputHandler] = useForm(
    {
      //inputs
      travel: {
        value: "", //initial value
        isValid: false, //initial validity
      },
    },
    true
  );

  const ItemType = "vehicleRoute";
  const ItemEndPointType = "vehicleroutes";

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const fetchTravelLocations = useCallback(
    async (travelid) => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/adminlive/${travelid}`);

        setTravelLocations(responseData.travelLocations);
        locationsREF.current = responseData.travelLocations;
        drawPolylines(responseData.travelLocations.map((item) => item?.location));

        //return responseData;
        //setLoadedItem(responseData[ItemType]);
        //setMarkerLocation(responseData[ItemType].location);
        //setItemState(Date.now());
      } catch (err) {}
    },
    [sendRequest]
  );

  const fetchTravels = useCallback(
    async (routeid) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/adminlive`,
          "POST",
          JSON.stringify({
            routeid,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        return responseData;
        //setLoadedItem(responseData[ItemType]);
        //setMarkerLocation(responseData[ItemType].location);
        //setItemState(Date.now());
      } catch (err) {}
    },
    [sendRequest, auth.token]
  );

  const fetchItem = useCallback(async () => {
    try {
      setIsItemLoaded(false);
      const currentVehicleRoute = vehicleRoutes.find((vehicleRoute) => vehicleRoute.id === itemId);
      setLoadedItem(currentVehicleRoute);

      if (!currentVehicleRoute) {
        setIsItemLoaded(true);
        return;
      }

      // Map over students and retrieve parent using hash maps
      setCurrentStudents(
        currentVehicleRoute.student.map((student) => ({
          ...student,
          location: isPickUp(currentVehicleRoute.type) ? student.locationgeton : student.locationgetoff,
          parent:
            studentToParentMap[student.id.toString()] && studentToParentMap[student.id.toString()]?.length > 0
              ? userMap[studentToParentMap[student.id.toString()][0]]
              : null,
        }))
      );

      const currentSchool = schools.find((school) => school.id.toString() === currentVehicleRoute.school.id.toString());
      setSchool(currentSchool);

      const routeid = itemId;
      const fetchedTravels = await fetchTravels(routeid);
      setTravels(fetchedTravels.travels);
      setTravelOptions(
        fetchedTravels.travels.map((travel) => {
          return {
            value: travel.id,
            label: travel.day,
          };
        })
      );
      // setItemState(Date.now());
    } catch (err) {
      console.log(err);
    }
    setIsItemLoaded(true);
  }, [vehicleRoutes, schools, itemId, fetchTravels, studentToParentMap, userMap]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const play = useCallback(() => {
    if (playerStateREF.current === PlayerStates.PLAYING) {
      if (indexREF.current === locationsREF.current.length - 1) {
        onIndexChangeHandler(0);
      } else {
        onIndexChangeHandler(indexREF.current + 1);
      }
    }
  }, []);

  useEffect(() => {
    const playerInterval = setInterval(() => {
      play();
    }, 500);

    return () => {
      clearInterval(playerInterval);
    };
  }, [play]);

  const fetchTravelActions = useCallback(
    async (travelid) => {
      if (!travelid) {
        return;
      }
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dailyaction/${travelid}`);

        let currentActions = responseData.travelActions;

        currentActions = currentActions.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          return 0;
        });

        setTravelActions(currentActions);

        await fetchTravelLocations(travelid);
        setIndex(0);

        //return responseData;
        //setLoadedItem(responseData[ItemType]);
        //setMarkerLocation(responseData[ItemType].location);
        //setItemState(Date.now());
      } catch (err) {}
    },
    [fetchTravelLocations, sendRequest]
  );

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const drawPolylines = (currentlocations) => {
    //mapREF.current = map;
    // mapsREF.current = maps;
    if (mapREF.current === null || mapsREF.current === null) {
      return;
    }
    if (currentlocations.length === 0) {
      return;
    }
    var bermudaTriangle = new mapsREF.current.Polyline({
      path: currentlocations,
      strokeColor: "#FF0000",
      strokeOpacity: 1,
      strokeWeight: 2,
    });
    bermudaTriangle.setMap(mapREF.current);
  };

  //#region Map Related
  const handleApiLoaded = (map, maps) => {
    mapREF.current = map;
    mapsREF.current = maps;

    const currentlocations = travelLocations?.map((item) => item?.location);
    drawPolylines(currentlocations);
    return;
  };
  // const triangleCoords = [
  //   { lat: 25.774, lng: -80.19 },
  //   { lat: 18.466, lng: -66.118 },
  //   { lat: 32.321, lng: -64.757 },
  //   { lat: 25.774, lng: -80.19 }
  // ];
  //   const currentlocations = travelLocations.map((item) => item?.location);

  //   if (currentlocations.length === 0) {
  //     return;
  //   }
  //   var bermudaTriangle = new maps.Polyline({
  //     path: currentlocations,
  //     strokeColor: "#FF0000",
  //     strokeOpacity: 1,
  //     strokeWeight: 2,
  //     // fillColor: "#FF0000",
  //     fillOpacity: 0,
  //   });
  //   bermudaTriangle.setMap(map);
  // };
  //#endregion Map Related

  const onIndexChangeHandler = (currentIndex) => {
    //vehicleMapHandler(mapREF,locationsREF.current[currentIndex].location,locationsREF.current[currentIndex+1].location);
    setIndex((prevIndex) => currentIndex);
    indexREF.current = currentIndex;
  };

  const onPlayerStateChangeHandler = (currentState) => {
    playerStateREF.current = currentState;
  };

  const onFullScreenChangeHandler = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,

      fullscreenControl: true,
      styles: [
        {
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: false,
      minZoom: 11,
      maxZoom: 25,

      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID],
      },

      zoomControl: false,
      clickableIcons: false,
    };
  };

  // #region Loading
  if (!isItemLoaded) {
    return <div className="center">{/* <LoadingSpinner /> */}</div>;
  }
  // #endregion Loading

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {currentStudents.length != 0 && travels.length != 0 ? (
        <div className="horizontal-flex" style={{ justifyContent: "flex-start" }}>
          <div
            className="card vertical-flex align-items-center justify-content-start height-full"
            style={{ rowGap: "20px" }}
          >
            <h4>{schoolOptions.find((school) => school.value === loadedItem.school.id.toString()).label}</h4>
            <span>
              <strong>{t("vehicleRoute.title")}:</strong> {loadedItem.name} (
              {isPickUp(loadedItem.type) ? t("pickUp") : t("dropOff")})
            </span>
            <span>
              <strong>{t("driver")}: </strong>
              {loadedItem?.driver[0]?.name || "-"}
            </span>

            <span>
              <strong>{t("phone")}: </strong>
              {loadedItem.driver[0]?.phonenumber || "-"}
            </span>

            <div>
              <h4 style={{ marginBottom: "0" }}>{t("selectRouteDate")}</h4>
              <CustomSelect
                options={travelOptions}
                id="travel"
                initialValue={travelOptions.find((option) => option.value === formState.inputs.travel.value)}
                onInput={inputHandler}
                initialValid={true}
                fireChange={fetchTravelActions}
              ></CustomSelect>
            </div>
          </div>

          {travelActions?.length != 0 ? (
            <div className="live-item-right-side">
              <div className="middle-container">
                <div className="player-container">
                  {travelLocations.length > 0 && !isLoading && (
                    <div style={{ width: "100%" }}>
                      <Player
                        onPlayerStateChange={onPlayerStateChangeHandler}
                        onPlayerIndexChange={onIndexChangeHandler}
                        onFullScreenChange={onFullScreenChangeHandler}
                        min={0}
                        max={travelLocations.length - 1}
                        value={index}
                        text={locationsREF.current[index]?.date.split(" ")[1]}
                      />
                    </div>
                  )}

                  {!isLoading && travelLocations.length > 0 ? (
                    <GoogleMapReact
                      options={getMapOptions}
                      key={itemId}
                      bootstrapURLKeys={{
                        key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                      }}
                      yesIWantToUseGoogleMapApiInternals //this is important!
                      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                      //defaultCenter={markerLocationMorning}
                      defaultCenter={{
                        lat: locationsREF.current[index]?.location.lat,
                        lng: locationsREF.current[index]?.location.lng,
                      }}
                      defaultZoom={16}
                    >
                      {locationsREF.current?.length && (
                        <Marker
                          Icon={DirectionsBus}
                          lat={locationsREF.current[index]?.location.lat}
                          lng={locationsREF.current[index]?.location.lng}
                          key={"asdasggh"}
                          info={
                            locationsREF.current[index]?.date.split(" ")[1] +
                            " - " +
                            Math.floor(locationsREF.current[index]?.speed * 3.6) +
                            " " +
                            t("kmhour")
                          }
                          alwaysShowMarker={true}
                          initialShow={true}
                        />
                      )}

                      <Marker
                        Icon={SchoolIcon}
                        lat={school.location.lat}
                        lng={school.location.lng}
                        key={"Okul"}
                        info={school.name}
                        alwaysShowMarker={true}
                      />

                      {currentStudents.map((student) => {
                        return (
                          <Marker
                            Icon={RoomIcon}
                            lat={student.location.lat}
                            lng={student.location.lng}
                            key={student.id}
                            info={student.name}
                            alwaysShowMarker={false}
                            initialShow={false}
                          />
                        );
                      })}
                    </GoogleMapReact>
                  ) : (
                    !isLoading && (
                      <div className="center width-full">Bu güzergah için araç konum bilgisi bulunmuyor.</div>
                    )
                  )}
                </div>

                {/* <div className="routeInfoStudents">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={5}>
                        {currentStudents.map((student) => {
                          return (
                            <Grid item xs={4}>
                              <StudentLiveCard
                                student={student}
                                parent={student.parent}
                                actions={travelActions}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </div> */}
              </div>{" "}
              {/* Route Info Map Ending */}
              <div className="actions-container">
                {travelActions.length != 0 && (
                  <div>
                    <div
                      style={{ paddingBottom: "10px", backgroundColor: "white", position: "sticky", zIndex: 1, top: 0 }}
                    >
                      <h4 style={{ margin: 0 }}> {t("routeMovements")}</h4>
                    </div>

                    {travelLocations && (
                      <div>
                        {travelActions.map((travelaction) => {
                          return (
                            <div>
                              <div
                                style={{
                                  background:
                                    locationsREF.current[index]?.date.split(" ")[1] >= travelaction?.date.split(" ")[1]
                                      ? "ForestGreen"
                                      : "Gray",
                                  marginBottom: "5px",
                                  color: "white",
                                  padding: "10px",
                                  paddingLeft: "10px",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                {travelaction?.date.split(" ")[1]} {" - "}
                                {(travelaction.actiontype === TRAVEL_ACTION_TYPE.STARTED ||
                                  travelaction.actiontype === TRAVEL_ACTION_TYPE.FINISHED ||
                                  travelaction.actiontype === TRAVEL_ACTION_TYPE.QR_READED) &&
                                  t(
                                    TRAVEL_ACTION_TYPE_EXPLANATIONS.find((exp) => exp.value === travelaction.actiontype)
                                      .label
                                  )}
                                {(travelaction.actiontype === TRAVEL_ACTION_TYPE.APPROACH_TO_HOME ||
                                  travelaction.actiontype === TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO) &&
                                  currentStudents.find((student) => student.id == travelaction.student)?.name}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL &&
                                  travelaction.action != ARRIVED_SCHOOL_TYPE.BUS_ARRIVED &&
                                  currentStudents.find((student) => student.id == travelaction.student)?.name}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL &&
                                  travelaction.action != ARRIVED_SCHOOL_TYPE.BUS_ARRIVED && <br />}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL &&
                                  t(
                                    ARRIVED_SCHOOL_TYPE_EXPLANATIONS.find((type) => type.value === travelaction.action)
                                      .label
                                  )}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.LEAVED_SCHOOL &&
                                  travelaction.action === LEAVED_SCHOOL_TYPE.BUS_LEAVED &&
                                  t(
                                    TRAVEL_ACTION_TYPE_EXPLANATIONS.find(
                                      (type) => type.value === TRAVEL_ACTION_TYPE.LEAVED_SCHOOL
                                    ).label
                                  )}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.LEAVED_SCHOOL &&
                                  travelaction.action !== LEAVED_SCHOOL_TYPE.BUS_LEAVED &&
                                  currentStudents.find((student) => student.id == travelaction.student)?.name +
                                    " " +
                                    t(
                                      TRAVEL_LEAVED_TYPE_EXPLANATIONS.find((type) => type.value === travelaction.action)
                                        .label
                                    )}
                                {(travelaction.actiontype === TRAVEL_ACTION_TYPE.APPROACH_TO_HOME ||
                                  travelaction.actiontype === TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO) && <br />}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.APPROACH_TO_HOME &&
                                  t(
                                    APPROACH_TO_HOME_TYPE_EXPLANATIONS.find(
                                      (type) => type.value === travelaction.action
                                    ).label
                                  )}
                                {travelaction.actiontype === TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO &&
                                  t(
                                    STUDENT_GETON_INFO_TYPE_EXPLANATIONS.find(
                                      (type) => type.value === travelaction.action
                                    ).label
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="routeInfoContainer" />
          )}
        </div>
      ) : (
        <div className="center width-full">{t("noHistoryForRoute")}</div>
      )}
    </React.Fragment>
  );
};

export default LiveItem;
