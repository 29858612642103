import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import {
  paymentMethods,
  banks,
  paymentTypes,
  isSinglePaymentOptions,
  installmentCountOptions,
} from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const ReceiptItem = ({ fixedContract, itemId, isNewItem, onClose, InsertItem, DeleteItem, UpdateItem, gotoItem }) => {
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { receipts, contractsOptions, shiftPersonelServiceOptions, userOptions, shifts } = useContext(StoreContext);

  const itemType = "receipt";
  const endPointUpdate = "receipts";
  const endPointDelete = "receipts";
  const endPointCreate = "receipts";

  const [paymentMethodKey, setPaymentMethodKey] = useState();
  const [isSinglePaymentKey, setIsSinglePaymentKey] = useState();

  const [formState, inputHandler] = useForm(
    {
      contract: { value: null, isValid: true },
      date: { value: "", isValid: true },
      paymentMethod: { value: "", isValid: true },
      paymentType: { value: "", isValid: true },
      amount: { value: null, isValid: true },
      bank: { value: null, isValid: true },
      receiptNo: { value: null, isValid: true },
      official: { value: true, isValid: true },
      isSinglePayment: { value: true, isValid: true },
      installmentCount: { value: 1, isValid: true },
      notes: { value: "", isValid: true },
    },
    true
  );

  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentReceipt = receipts.find((receipt) => receipt?.id === itemId);
      setLoadedItem(currentReceipt);
    } catch (err) {}
    setIsItemLoaded(true);
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const setItemState = (item, value, valid, setState) => {
    setState(item + "-" + Math.random());
    inputHandler(item, value, true);
    setLoadedItem((prevItem) => {
      return {
        ...prevItem,
        [item]: value,
      };
    });
  };

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    const infoToBeSent = {
      contract: formState.inputs.contract.value,
      date: formState.inputs.date.value,
      paymentMethod: formState.inputs.paymentMethod.value,
      paymentType: formState.inputs.paymentType.value,
      amount: formState.inputs.amount.value,
      bank: formState.inputs.bank.value,
      receiptNo: formState.inputs.receiptNo.value,
      official: true,
      isSinglePayment:
        formState.inputs.paymentMethod.value === 2 ? formState?.inputs?.isSinglePayment?.value : undefined,
      installmentCount:
        formState.inputs.paymentMethod.value === 2 && formState?.inputs?.isSinglePayment?.value === false
          ? formState?.inputs?.installmentCount?.value
          : undefined,
      notes: formState.inputs.notes.value,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };
  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        // onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        // onClose={() => {
        //   setGeneratedItemId(false);
        //   onClose();
        // }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <CustomSelect
            options={contractsOptions}
            id="contract"
            onInput={inputHandler}
            label="Öğrenci Sözleşmesi"
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            initialValue={
              !fixedContract
                ? contractsOptions.find(
                    (contract) => contract.value.toString() === loadedItem?.contract?.id?.toString()
                  )
                : contractsOptions.find((contract) => contract.value.toString() === fixedContract?.id?.toString())
            }
            disabled={fixedContract}
          ></CustomSelect>
          <DatePicker
            id="date"
            onInput={inputHandler}
            label="Tahsilat Tarihi"
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={loadedItem?.date?.toString()}
          ></DatePicker>
          <div className="horizontal-flex">
            <CustomSelect
              options={paymentMethods}
              id="paymentMethod"
              onInput={inputHandler}
              label="Ödeme Yöntemi"
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
              initialValue={paymentMethods.find(
                (paymentMethod) => paymentMethod.value.toString() === loadedItem?.paymentMethod?.toString()
              )}
            ></CustomSelect>

            <CustomSelect
              options={paymentTypes}
              key={paymentMethodKey}
              id="paymentType"
              onInput={inputHandler}
              label="Ödeme Şekli"
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
              initialValue={paymentTypes.find(
                (paymentType) => paymentType.value.toString() === loadedItem?.paymentType?.toString()
              )}
            ></CustomSelect>
          </div>
          {formState.inputs?.paymentMethod?.value === 2 && (
            <div>
              <CustomSelect
                options={isSinglePaymentOptions}
                id="isSinglePayment"
                onInput={inputHandler}
                label="Tek Çekim / Taksitli"
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={
                  isSinglePaymentOptions.find(
                    (isSinglePayment) => isSinglePayment.value.toString() === loadedItem?.isSinglePayment?.toString()
                  ) || isSinglePaymentOptions[0]
                }
              ></CustomSelect>
            </div>
          )}

          {formState.inputs?.paymentMethod?.value === 2 && formState.inputs?.isSinglePayment?.value === false && (
            <CustomSelect
              options={installmentCountOptions}
              id="installmentCount"
              onInput={inputHandler}
              label="Taksit Sayısı"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={installmentCountOptions.find(
                (installmentCount) => installmentCount.value.toString() === loadedItem?.installmentCount?.toString()
              )}
            />
          )}

          <div className="horizontal-flex">
            <CustomSelect
              options={banks}
              id="bank"
              onInput={inputHandler}
              label="Ödemenin Yapıldığı Banka"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={banks.find((bank) => bank.value.toString() === loadedItem?.bank?.toString())}
            ></CustomSelect>

            <Input
              id="receiptNo"
              element="input"
              type="text"
              label="Tahsilat Makbuz Numarası"
              validators={[VALIDATOR_NONE()]}
              errorText="Fatura no giriniz."
              initialValue={loadedItem?.receiptNo}
              initialValid={true}
              onInput={inputHandler}
            />
          </div>
          <Input
            id="amount"
            element="input"
            type="text"
            label="Ödeme Tutarı"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Ödeme tutarını giriniz."
            initialValue={loadedItem?.amount}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
          />

          <Input
            id="notes"
            element="textarea"
            type="text"
            label="Notlar"
            rows={3}
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem?.notes || ""}
            initialValid={true}
            onInput={inputHandler}
          />
          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={
              !formState.inputs?.contract?.value || !formState.inputs?.date?.value || !formState.isValid || isLoading
            }
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default ReceiptItem;
