// ModalRoot.js
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useModal } from "./ModalContext";
import "./Modal.css";

const ModalRoot = () => {
  const { isOpen, modalContent, closeModal } = useModal();
  const [modalContainer, setModalContainer] = useState(null);

  useEffect(() => {
    // Sayfada modal-root yoksa otomatik oluştur:
    let root = document.getElementById("modal-root");
    if (!root) {
      root = document.createElement("div");
      root.id = "modal-root";
      document.body.appendChild(root);
    }
    setModalContainer(root);
  }, []);

  // henüz DOM'a eklenmediyse (setModalContainer tamamlanmadıysa):
  if (!modalContainer) return null;
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={closeModal}>
          X
        </button>
        {modalContent}
      </div>
    </div>,
    modalContainer
  );
};

export default ModalRoot;
