import React, { useContext } from "react";

import { AuthContext } from "shared/context/auth-context";
import { useTranslation } from "react-i18next";

import InventoryVehicles from "./inventory-vehicles/InventoryVehicles";
import InventoryDrivers from "./inventory-drivers/InventoryDrivers";

import Tabs from "components/shared/Tabs";

const Accounting = () => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!auth.permissions.accessToInventory) {
    return <div className="center">Envanter uygulaması erişiminiz bulunmuyor.</div>;
  }

  let accountingTabs = [];

  accountingTabs.push({
    label: t("Araçlar"),
    content: <InventoryVehicles />,
  });

  accountingTabs.push({
    label: t("Sürücüler"),
    content: <InventoryDrivers />,
  });

  // accountingTabs.push({
  //   label: t("newContract"),
  //   content: <ContractItem isNewItem={true} InsertItem={InsertContract} />,
  // });

  // accountingTabs.push({
  //   label: t("receipts"),
  //   content: <Receipts />,
  // });

  // accountingTabs.push({
  //   label: t("Yeni Tahsilat"),
  //   content: <ReceiptItem isNewItem={true} InsertItem={InsertReceipt} />,
  // });

  // accountingTabs.push({
  //   label: t("invoices"),
  //   content: <Invoice />,
  // });

  // accountingTabs.push({
  //   label: t("refunds"),
  //   content: <Withdraws />,
  // });

  // accountingTabs.push({
  //   label: t("vehicleCompensations"),
  //   content: <VehiclePayments />,
  // });

  // accountingTabs.push({
  //   label: t("reports"),
  //   content: <Report />,
  // });

  // accountingTabs.push({
  //   label: t("expenses"),
  //   content: <Expense />,
  // });

  // accountingTabs.push({
  //   label: t("contractTemplates"),
  //   content: <ServiceTemplate />,
  // });

  // accountingTabs.push({
  //   label: t("discounts"),
  //   content: <Discount />,
  // });

  // accountingTabs.push({
  //   label: t("servicePeriods"),
  //   content: <EducationYear />,
  // });

  return (
    <React.Fragment>
      <Tabs tabs={accountingTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />
    </React.Fragment>
  );
};

export default Accounting;
