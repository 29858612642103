import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import NotFound from "shared/components/UIElements/NotFound";
import MapSinglePoint from "components/shared/MapSinglePoint";
import FormFooter from "shared/components/FormElements/FormFooter";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";

import {
  VALIDATOR_REQUIRE,
  VALIDATOR_PHONENUMBER,
  VALIDATOR_NONE,
  VALIDATOR_MAX,
  VALIDATOR_MIN,
} from "shared/util/validators";

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";
import { yesNoOptions } from "shared/util/types";
import { PERMISSION_TYPES } from "shared/util/constants";

const UserItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { schoolOptions, transferregions, transferregionOptions, schools, userTypes, users } = useContext(StoreContext);
  const auth = useContext(AuthContext);

  // item info
  const itemType = "user";
  const endPointUpdate = "users";
  const endPointDelete = "users";
  const endPointCreate = "users/signup";

  // item special states
  const [showOptionalField, setShowOptionalField] = useState(false);
  const [markerLocation, setMarkerLocation] = useState();
  const [userAddress, setUserAddress] = useState();
  const [addressInSync, setAddressInSync] = useState(false);

  // item special functions
  const mapDataChangeHandler = (address, location, inSync) => {
    setMarkerLocation(location);
    setUserAddress(address);
    setAddressInSync(inSync);
    inputHandler("address", address, true);
  };

  // formstates
  const [formState, inputHandler] = useForm(
    {
      //inputs
      name: { value: "", isValid: true },
      isshift: { value: false, isValid: true },
      phonenumber: { value: "", isValid: true },
      email: { value: "", isValid: true },
      address: { value: null, isValid: true },
      identity: { value: "", isValid: true },

      //selects
      usertype: { value: userTypes[0].value, isValid: true },
      school: { value: schoolOptions.length === 1 ? schoolOptions[0].value : null, isValid: true },
    },
    true
  );

  // optional formstates
  const [formStateOptional, inputHandlerOptional, setFormDataOptional] = useForm(
    {
      cardId: { value: null, isValid: true },
      description: { value: null, isValid: true },
      getonstop: { value: null, isValid: true },
      getoffstop: { value: null, isValid: true },
      distancegeton: { value: null, isValid: true },
      approachtogetonnotification: { value: null, isValid: true },
      shiftchangenotification: { value: null, isValid: true },
      transferregion: { value: null, isValid: true },
    },
    true
  );

  // formstates fetch
  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentUser = users.find((user) => user.id === itemId);
      setLoadedItem(currentUser);
      if (currentUser?.location && currentUser?.location.lat && currentUser?.location.lng) {
        setMarkerLocation(currentUser?.location);
        setAddressInSync(true);
      } else {
        setMarkerLocation(null);
        setAddressInSync(false);
      }

      setUserAddress(currentUser?.address);

      setFormDataOptional(
        {
          cardId: { value: null, isValid: true },
          description: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          distancegeton: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
          transferregion: { value: null, isValid: true },
        },
        true
      );
    } catch (err) {}
    setIsItemLoaded(true);
  }, [users, setFormDataOptional, itemId]);

  // standard code
  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  // optional formstates fetch
  useEffect(() => {
    const currentSchool = schools.find((school) => school.id.toString() === formState.inputs.school?.value?.toString());

    if (currentSchool?.usemagneticcard) {
      setFormDataOptional(
        {
          cardId: { value: null, isValid: true },
          description: { value: null, isValid: true },
          distancegeton: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
          transferregion: { value: null, isValid: true },
        },
        true
      );
      setShowOptionalField(true);
    } else {
      setShowOptionalField(false);
      setFormDataOptional(
        {
          cardId: { value: undefined, isValid: true },
          description: { value: null, isValid: true },
          distancegeton: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
          transferregion: { value: null, isValid: true },
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.inputs.school?.value]);

  // item update
  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const infoToBeSent = {
        name: formState.inputs.name.value.toString().trim(),
        phonenumber: formState.inputs.phonenumber.value.toString().trim(),
        cardId: formStateOptional.inputs.cardId.value,
        description: formStateOptional.inputs.description.value,
        distancegeton: formStateOptional.inputs.distancegeton.value,
        isshift: formState.inputs.isshift.value,
        getonstop: formStateOptional.inputs.getonstop.value,
        getoffstop: formStateOptional.inputs.getoffstop.value,
        approachtogetonnotification: formStateOptional.inputs.approachtogetonnotification.value,
        shiftchangenotification: formStateOptional.inputs.shiftchangenotification.value,
        usertype: formState.inputs.usertype.value,
        email: formState.inputs.email.value,
        address: userAddress,
        identity: formState.inputs.identity.value,
        school: formState.inputs.school.value,
        transferregion: formStateOptional.inputs.transferregion.value,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  const getTransferRegionsOptions = useCallback(() => {
    if (formState.inputs.school.value) {
      const currentTransferRegions = transferregions.filter(
        (region) => region.school === formState.inputs.school.value
      );
      return currentTransferRegions.map((region) => {
        return { value: region.id, label: region.name };
      });
    }
    return [];
  }, [formState.inputs.school.value, transferregions]);

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container">
          {schoolOptions.length > 1 && (
            <CustomSelect
              options={schoolOptions}
              id="school"
              onInput={inputHandler}
              label={t("client")}
              initialValue={schoolOptions.find((option) => option.value === loadedItem?.school.toString())}
              initialValid={isNewItem ? false : true}
            ></CustomSelect>
          )}

          {/* <CustomSelect
              options={userTypes}
              id="usertype"
              onInput={inputHandler}
              label={t("userType")}
              initialValue={userTypes.find((option) => option.value.toString() === loadedItem?.usertype.toString())}
              initialValid={isNewItem ? false : true}
            ></CustomSelect> */}

          <div className="horizontal-flex">
            <Input
              id="name"
              element="input"
              type="text"
              label={t("name")}
              validators={[VALIDATOR_REQUIRE()]}
              errorText={t("requireField", { item: t("name") })}
              initialValue={loadedItem?.name}
              initialValid={isNewItem ? false : true}
              onInput={inputHandler}
            />
            <Input
              id="phonenumber"
              element="input"
              type="text"
              label={t("phone")}
              validators={[VALIDATOR_NONE]}
              errorText={t("requireField", { item: t("phone") })}
              initialValue={loadedItem?.phonenumber || ""}
              initialValid={true}
              onInput={inputHandler}
            />
          </div>

          <MapSinglePoint
            id="userItemMap"
            label={formState.inputs.name.value}
            initialAddress={userAddress || ""}
            initialAddressInSync={addressInSync}
            initialLocation={markerLocation}
            onInput={inputHandler}
            onMapDataChange={mapDataChangeHandler}
          ></MapSinglePoint>

          <div className="horizontal-flex">
            <Input
              id="identity"
              element="input"
              type="text"
              label={t("identityNumber")}
              validators={[VALIDATOR_NONE()]}
              errorText={t("requireField", { item: t("identityNumber") })}
              initialValue={loadedItem?.identity || ""}
              initialValid={true}
              onInput={inputHandler}
            />

            <Input
              id="email"
              element="input"
              type="text"
              label={t("email")}
              validators={[VALIDATOR_NONE()]}
              errorText={t("requireField", { item: t("email") })}
              onInput={inputHandler}
              initialValue={loadedItem?.email || ""}
              initialValid={true}
            />
          </div>

          {showOptionalField && (
            <div>
              <div className="horizontal-flex">
                {auth.permissions[PERMISSION_TYPES.USE_CARD_ID] && (
                  <Input
                    id="cardId"
                    element="input"
                    type="text"
                    label={t("jobClass")}
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.cardId || ""}
                    initialValid={true}
                    onInput={inputHandlerOptional}
                  />
                )}

                {auth.permissions[PERMISSION_TYPES.USE_DESCRIPTION] && (
                  <Input
                    id="description"
                    element="input"
                    type="text"
                    label={t("description")}
                    validators={[VALIDATOR_NONE()]}
                    initialValue={loadedItem?.description || ""}
                    initialValid={true}
                    onInput={inputHandlerOptional}
                  />
                )}
              </div>

              <div className="horizontal-flex">
                <CustomSelect
                  options={yesNoOptions}
                  id="isshift"
                  onInput={inputHandler}
                  initialValue={yesNoOptions.find((option) => option.value === loadedItem?.isshift)}
                  label={t("isshift")}
                ></CustomSelect>

                <CustomSelect
                  options={transferregionOptions}
                  id="transferregion"
                  onInput={inputHandlerOptional}
                  initialValue={
                    transferregionOptions.find((option) => option.value === loadedItem?.transferregion) || null
                  }
                  label={t("transferregion.title")}
                ></CustomSelect>
              </div>

              {/* <CustomSelect
                options={stopOptions}
                id="getonstop"
                onInput={inputHandlerOptional}
                label={t("getonstop")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={
                  stopOptions?.find((option) => option?.value === loadedItem?.getonstop?.toString()) || null
                }
              ></CustomSelect>

              <CustomSelect
                options={stopOptions}
                id="getoffstop"
                onInput={inputHandlerOptional}
                label={t("getoffstop")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={
                  stopOptions?.find((option) => option?.value === loadedItem?.getoffstop?.toString()) || null
                }
              ></CustomSelect> */}

              <CustomSelect
                options={yesNoOptions}
                id="approachtogetonnotification"
                onInput={inputHandlerOptional}
                initialValue={
                  yesNoOptions.find((option) => option.value === loadedItem?.approachtogetonnotification) ||
                  yesNoOptions[0]
                }
                label={t("approachtogetonnotification")}
              ></CustomSelect>

              {/* <CustomSelect
                options={yesNoOptions}
                id="shiftchangenotification"
                onInput={inputHandlerOptional}
                initialValue={yesNoOptions.find((option) => option.value === loadedItem?.shiftchangenotification)}
                label={t("shiftchangenotification")}
              ></CustomSelect> */}

              <Input
                id="distancegeton"
                element="input"
                type="text"
                label="Biniş Öncesi Bildirim Mesafesi"
                validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
                initialValue={loadedItem?.distancegeton || "2"}
                initialValid={true}
                onInput={inputHandlerOptional}
                errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
              />
            </div>
          )}

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !formState.inputs.school.value || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default UserItem;
