import React, { useReducer, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import { red } from "@mui/material/colors";

import { validate } from "../../util/validators";
import "./Input.css"; // Assuming shared styles

const checkboxReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const Checkbox = (props) => {
  const [checkboxState, dispatch] = useReducer(checkboxReducer, {
    value: props.initialValue || false,
    isTouched: false,
    isValid: props.initialValid || true,
  });

  const { id, onInput } = props;
  const { value, isValid } = checkboxState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    const newValue = event.target.checked;
    dispatch({ type: "CHANGE", val: newValue, validators: props.validators });
    dispatch({ type: "TOUCH" });

    if (props?.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div>
      <label htmlFor={id} style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
        <input
          type="checkbox"
          id={id}
          checked={value}
          onChange={changeHandler}
          style={{ width: "18px", height: "18px", marginRight: "8px", marginLeft: "0px" }} // Adjust size
        />
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
