import React, { useContext, useMemo } from "react";
import NotFound from "pages/auth/NotFound";
import { AuthContext } from "shared/context/auth-context";
import Transfers from "components/transfer-management/Transfers";
import TransferNew from "components/transfer-management/TransferNew";
import TransferNewExcel from "components/transfer-management/TransferNewBulk";
import TransferRegions from "components/transfer-management/TransferRegions";
import TransferReport from "components/transfer-management/TransferReport";
import Tabs from "components/shared/Tabs";

const TransferManagement = () => {
  const auth = useContext(AuthContext);
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const transferManagementTabs = useMemo(
    () => [
      {
        label: "Aktif Transferler",
        content: <Transfers active={true} />,
      },
      {
        label: "Tüm Transferler",
        content: <Transfers active={false} />,
      },
      {
        label: "Transfer Dosyası Yükle",
        content: <TransferNewExcel />,
      },
      {
        label: "Yeni Transfer",
        content: <TransferNew />,
      },
      {
        label: "Raporlama",
        content: <TransferReport />,
      },
      {
        label: "Bölgeler",
        content: <TransferRegions />,
      },
    ],
    []
  );

  if (!auth.permissions.accessToTransfers) {
    return <NotFound />;
  }

  return <Tabs tabs={transferManagementTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />;
};

export default TransferManagement;
