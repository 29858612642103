import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MapWithTransferTimesContainer from "components/shared/MapWithTransferTimesContainer";

import PersonelTab from "./tabs/Personel";
import ServiceTab from "./tabs/Service";
import TransferTab from "./tabs/Transfer";

import { StoreContext } from "shared/context/store-context";

import { useShiftBackend } from "shared/hooks/backend/shift-hook";
import { useFreeSms } from "shared/hooks/backend/free-sms-hook";

import { CheckTimeString } from "shared/util/time";
import Tabs from "components/shared/Tabs";
import { findUserInServices } from "shared/util/relationaldata";

const TransferAnalyzer = ({ school, transfer, transfertime, services, displayName, servicesOptions, onClose }) => {
  const { t } = useTranslation();
  const { users, UpdatePersonelService, InsertPersonelService, DeletePersonelService, UpdateTransfer } =
    useContext(StoreContext);

  const [type, setType] = useState("100");

  const {
    updateUsersInTheService,
    updateUsersDetailsInTheService,
    addService,
    deleteService,
    updateVehicleRoutesInTheTransfer,
  } = useShiftBackend();

  const [usersInTheTransfer, setUsersInTheTransfer] = useState([]);

  useEffect(() => {
    if (services.length) {
      const allUsersIds = services.reduce((acc, service) => {
        return [...acc, ...service.users.map((user) => user.id)];
      }, []);
      setUsersInTheTransfer(users.filter((user) => allUsersIds.includes(user.id)));
    }
  }, [services, transfer, users]);

  const { sendFreeSms } = useFreeSms();

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updateUsersInTheServiceHandler = React.useCallback(
    async (serviceId, users) => {
      if (!serviceId) {
        return false;
      }
      const newPersonelService = await updateUsersInTheService(serviceId, users);
      if (newPersonelService) {
        UpdatePersonelService(newPersonelService);
        return true;
      }
      return false;
    },
    [updateUsersInTheService, UpdatePersonelService]
  );

  const changeUserServiceHandler = React.useCallback(
    async (userId, serviceId) => {
      // Find the user in the current shift
      const user = users.find((u) => u.id === userId);
      if (!user) return false; // If user doesn't exist, exit early

      // Find the user's current service
      const currentUserService = findUserInServices(services, userId);

      // If the user is already assigned to the target service, return true
      if (currentUserService?.id === serviceId) return true;

      let success = false;

      // Remove the user from the current service
      if (currentUserService) {
        currentUserService.users = currentUserService.users.filter((u) => u.id !== userId);
        success = await updateUsersInTheServiceHandler(
          currentUserService.id,
          currentUserService.users.map((u) => u.id)
        );
        if (!success) return false; // Exit if the removal fails
      }

      // Add the user to the new service (if specified)
      if (serviceId) {
        const targetService = services.find((service) => service.id === serviceId);
        if (!targetService) return false; // If the target service doesn't exist, exit early

        targetService.users.push(user);
        success = await updateUsersInTheServiceHandler(
          targetService.id,
          targetService.users.map((u) => u.id)
        );
      } else {
        // If no target service is specified, the operation is successful
        success = true;
      }

      if (success) {
        window.toast.success(t("updateSuccess"));
      }
      return success;
    },
    [users, services, updateUsersInTheServiceHandler, t]
  );

  const addPersonelHandler = React.useCallback(
    // eslint-disable-next-line no-unused-expressions
    async (personelId) => {
      if (!usersInTheTransfer.map((user) => user.id).includes(personelId)) {
        const user = users.find((user) => user.id === personelId);
        setUsersInTheTransfer([user, ...usersInTheTransfer]);
      }
    },
    [users, usersInTheTransfer]
  );

  const removePersonel = React.useCallback(
    async (personelId) => {
      const success1 = await changeUserServiceHandler(personelId, null);
      if (success1) {
        setUsersInTheTransfer(usersInTheTransfer.filter((user) => user.id !== personelId));
      }

      // const sucess2 = await updateUsersInTheShiftHandler(
      //   usersInTheShift.map((user) => user.id).filter((id) => id !== personelId)
      // );
      // if (success1 && sucess2) {
      //   setUsersInTheShift(usersInTheShift.filter((user) => user.id !== personelId));
      // }
    },
    [changeUserServiceHandler, usersInTheTransfer]
  );

  const updateUsersDetailsInTheServiceHandler = React.useCallback(
    async (serviceId, usersdetails) => {
      if (!serviceId || !usersdetails || !usersdetails.length) {
        return;
      }

      const personelservice = await updateUsersDetailsInTheService(serviceId, usersdetails);

      if (personelservice) {
        UpdatePersonelService(personelservice);
        return true;
      }
      return false;
    },
    [updateUsersDetailsInTheService, UpdatePersonelService]
  );

  const changeUserTransferTimeHandler = React.useCallback(
    async (userId, transferTime) => {
      if (transferTime === null || transferTime === undefined) {
        return;
      }
      if (transferTime?.length) {
        if (!CheckTimeString(transferTime)) {
          window.toast.error("Lütfen geçerli bir saat giriniz. Örnek: 21:30");
          return;
        }
      }

      const user = users.find((user) => user.id === userId);
      const newServicesIntheShift = [...services];
      if (user) {
        const currentUserService = newServicesIntheShift.find((service) =>
          service.users?.map((user) => user.id).includes(userId)
        );
        if (currentUserService) {
          const currentUsersDetails = currentUserService.usersdetails;
          const currentUserDetails = currentUsersDetails.find((userdetail) => userdetail?.user === userId);
          const currentUserTransferTime = currentUserDetails?.transfertime;
          if (currentUserTransferTime && currentUserTransferTime === transferTime) {
            return;
          }
          if (transferTime) {
            if (!currentUserDetails) {
              currentUserService.usersdetails.push({ user: userId, transfertime: transferTime, smses: [] });
            } else if (currentUserDetails) {
              currentUserDetails.transfertime = transferTime;
            }
          } else if (
            (transferTime === null || transferTime === undefined || transferTime === "") &&
            currentUserTransferTime
          ) {
            if (!currentUserDetails) {
              currentUserService.usersdetails.push({ user: userId, transfertime: "", smses: [] });
            } else if (currentUserDetails) {
              currentUserDetails.transfertime = "";
            }
          } else {
            return;
          }

          const success = await updateUsersDetailsInTheServiceHandler(currentUserService.id, currentUsersDetails);
          if (success) {
            window.toast.success(t("updateSuccess"));
          }
        }
      }
    },
    [users, services, updateUsersDetailsInTheServiceHandler, t]
  );

  const sendSMSHandler = React.useCallback(
    async (service, userId, text) => {
      const foundService = findUserInServices(services, userId);
      if (!foundService) {
        return;
      }

      const user = users.find((user) => user.id === userId);

      const phone = user.phonenumber;
      //const phone = "5355802151";

      // if (!phone.trim().startsWith("5")) {
      //   window.toast.error("Geçersiz telefon numarası.");
      //   return;
      // }

      const sendSms = await sendFreeSms(phone, text);

      if (sendSms) {
        const currentUsersDetails = [...service.usersdetails];
        const currentUserDetails = currentUsersDetails.find((userdetail) => userdetail?.user === userId);
        if (!currentUserDetails) {
          service.usersdetails.push({ user: userId, transfertime: "", smses: [sendSms] });
        } else {
          currentUserDetails.smses.push(sendSms);
        }

        await updateUsersDetailsInTheServiceHandler(
          service.id,
          service.usersdetails.map((userdetail) => {
            if (userdetail.user === userId) {
              return currentUserDetails;
            }
            return userdetail;
          })
        );

        window.toast.success(t("Sms gönderildi."));

        //setCurrentSmses([...currentSmses, sendSms]);
      }
    },
    [services, users, sendFreeSms, updateUsersDetailsInTheServiceHandler]
  );

  const sendAllSMSHandler = React.useCallback(
    async (messageContexts) => {
      for (const messageContext of messageContexts) {
        const { service, userId, text } = messageContext;
        await sendSMSHandler(service, userId, text);
      }
    },
    [sendSMSHandler]
  );

  const addServiceHandler = React.useCallback(
    async (name, drivers, plate, starttime, stoptime, routeClientArrivalTime) => {
      const infoToBeSent = {
        school: school.id,
        type: type,
        date: transfer.transferdate,
        users: [],
        isshift: false,
        status: 1,
        name: name,
        drivers: drivers,
        plate: plate,
        starttime: "00:00",
        stoptime: "23:59",
        routeClientArrivalTime: routeClientArrivalTime || undefined,
      };
      const newPersonelService = await addService(infoToBeSent);
      const updatedTransfer = await updateVehicleRoutesInTheTransfer(transfer.id, [
        ...transfer.vehicleRoutes,
        newPersonelService.id,
      ]);
      if (newPersonelService && updatedTransfer) {
        InsertPersonelService(newPersonelService);
        UpdateTransfer(updatedTransfer);
        window.toast.success(t("updateSuccess"));
      }
    },
    [
      school.id,
      type,
      transfer.transferdate,
      transfer.id,
      transfer.vehicleRoutes,
      addService,
      updateVehicleRoutesInTheTransfer,
      InsertPersonelService,
      UpdateTransfer,
      t,
    ]
  );

  const deleteServiceHandler = React.useCallback(
    async (serviceId) => {
      const success = await deleteService(serviceId);
      if (success) {
        DeletePersonelService(serviceId);
        updateVehicleRoutesInTheTransfer(
          transfer.id,
          transfer.vehicleRoutes.filter((route) => route !== serviceId)
        );
        UpdateTransfer({ ...transfer, vehicleRoutes: transfer.vehicleRoutes.filter((route) => route !== serviceId) });
        window.toast.success(t("updateSuccess"));
      }
    },
    [deleteService, DeletePersonelService, updateVehicleRoutesInTheTransfer, transfer, UpdateTransfer, t]
  );

  const transferAnalyzerTabs = React.useMemo(() => {
    const transferTab = <TransferTab transfer={transfer} onClose={onClose}></TransferTab>;

    const personelTab = (
      <PersonelTab
        users={usersInTheTransfer}
        services={services}
        servicesOptions={servicesOptions}
        school={school}
        // shift={shift}
        date={transfer.transferdate}
        displayName={displayName}
        onAddPersonel={addPersonelHandler}
        onRemovePersonel={removePersonel}
        onChangeUserService={changeUserServiceHandler}
        onChangeUserTransferTime={changeUserTransferTimeHandler}
        onSendSms={sendSMSHandler}
        onSendAllSms={sendAllSMSHandler}
      ></PersonelTab>
    );

    const serviceTab = (
      <ServiceTab
        school={school}
        type={type}
        users={usersInTheTransfer}
        transfertime={transfertime}
        services={services}
        date={transfer.transferdate}
        displayName={displayName}
        changeUserService={changeUserServiceHandler}
        addPersonel={addPersonelHandler}
        removePersonel={removePersonel}
        addService={addServiceHandler}
        deleteService={deleteServiceHandler}
      ></ServiceTab>
    );

    return [
      { label: "Transfer Bilgileri", content: transferTab },
      {
        label: "Transferdeki Personeller",
        content: personelTab,
      },
      { label: "Transferdeki Servisler", content: serviceTab },
    ];
  }, [
    transfer,
    usersInTheTransfer,
    services,
    servicesOptions,
    school,
    displayName,
    addPersonelHandler,
    removePersonel,
    changeUserServiceHandler,
    changeUserTransferTimeHandler,
    sendSMSHandler,
    type,
    transfertime,
    addServiceHandler,
    deleteServiceHandler,
  ]);

  return (
    <div>
      <Tabs tabs={transferAnalyzerTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={false} />
      {usersInTheTransfer.length > 0 && (
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
            <MapWithTransferTimesContainer
              stops={usersInTheTransfer}
              origin={school}
              type={type}
              enablePolyline={false}
              transferStartFinishTime={transfertime}
              isFullScreen={true}
              // onOptimize={(newStops) => {
              //   setCurrentUsers(newStops);
              // }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default TransferAnalyzer;
