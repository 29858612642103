import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { decode } from "@googlemaps/polyline-codec";
import { useTranslation } from "react-i18next";

import { subtractSecondsFromDate, subtractSecondsFromTime } from "shared/util/time";
import GenericModal from "components/shared/GenericModal";
import { ProgressBar } from "react-loader-spinner";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { getDirection, getDirectionsTSPDetails } from "shared/util/location";

import Button from "shared/components/FormElements/Button";

import "./Map.css";

// #region Components

import Marker from "shared/components/UIElements/Marker";

// eslint-disable-next-line react/display-name
const Map = React.memo(({ stops, type, origin, enablePolyline, transferStartFinishTime, isFullScreen, onOptimize }) => {
  //stops: id, name, location: {lat, lng}, transfertime
  //origin: id, name, location: {lat, lng}, transfertime

  const directionsREF = useRef([]);
  // distance: {from, to, distance, duration}

  const { t } = useTranslation();

  const auth = useContext(AuthContext);
  const mapREF = useRef(null);
  const mapsREF = useRef(null);
  const polylinesREF = useRef([]);
  const polyLineLabelsREF = useRef([]);

  const [loading, setLoading] = useState(false);
  const currentVersion = useRef(0);

  const [totalDistance, setTotalDistance] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [mapLoaded, setMapLoaded] = useState(false);

  const [mapCenter, setMapCenter] = useState({ lat: 36.8969, lng: 30.7133 });
  const [mapZoom, setMapZoom] = useState(14);

  const { sendRequest } = useHttpClient();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const handleApiLoaded = (map, maps) => {
    mapREF.current = map;
    mapsREF.current = maps;
    setMapLoaded(true);
    drawPolylines();
  };

  const drawPolylines = useCallback(async () => {
    setLoading(true);
    const version = ++currentVersion.current; // Increment version

    if (!mapREF.current || !mapsREF.current) {
      setLoading(false);
      return;
    }

    if (!enablePolyline) {
      setLoading(false);
      return;
    }

    for (var k = 0; k < polylinesREF.current.length; k++) {
      polylinesREF.current[k].setMap(null);
    }
    for (var l = 0; l < polyLineLabelsREF.current.length; l++) {
      polyLineLabelsREF.current[l].setMap(null);
    }
    const currentPolylines = [];
    let totalDistanceData = 0;
    let totalDurationData = 0;

    let stopsToAsk = [];
    if (type.toString() === "100") {
      stopsToAsk = stopsToAsk.concat(stops);
      stopsToAsk.push(origin);
    } else {
      stopsToAsk.push(origin);
      stopsToAsk = stopsToAsk.concat(stops);
    }

    const directionsToAsk = [];
    for (let i = 0; i < stopsToAsk.length - 1; i++) {
      const stop = stopsToAsk[i];
      const nextStop = stopsToAsk[i + 1];

      directionsToAsk.push({
        origin: stop,
        destination: nextStop,
      });
    }

    const directions = await Promise.all(
      directionsToAsk.map(async (direction) => {
        const directionDetails = await getDirection(direction.origin, direction.destination, auth.token);
        return { directionDetails, id: direction.origin?.id };
      })
    );

    for (var i = 0; i < directions.length; i++) {
      if (!directions[i]?.directionDetails?.overview_polyline) {
        setModalTitle(t("directionError"));
        setModalMessage(
          t("directionErrorDetailed", {
            originName: directionsToAsk[i].origin.name,
            destinationName: directionsToAsk[i].destination.name,
          })
        );
        setShowModal(true);
        break;
      }
    }

    addtoDirections(directions);

    if (currentVersion.current !== version) {
      return;
    }

    totalDistanceData = directions.reduce((acc, direction) => acc + direction.directionDetails.distance, 0);
    totalDurationData = directions.reduce((acc, direction) => acc + direction.directionDetails.duration, 0);

    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    for (var j = 0; j < directions.length; j++) {
      if (!directions[j].directionDetails?.overview_polyline?.points) {
        continue;
      }

      const directionPath = decode(directions[j].directionDetails.overview_polyline.points).map((coordinate) => ({
        lat: coordinate[0],
        lng: coordinate[1],
      }));

      // eslint-disable-next-line no-loop-func
      directionPath.forEach(({ lat, lng }) => {
        minLat = Math.min(minLat, lat);
        maxLat = Math.max(maxLat, lat);
        minLng = Math.min(minLng, lng);
        maxLng = Math.max(maxLng, lng);
      });

      var polyline = new mapsREF.current.Polyline({
        path: directionPath,
        strokeColor: "#FF0000",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillOpacity: 0,
        icons: [
          {
            icon: { path: mapsREF.current.SymbolPath.FORWARD_CLOSED_ARROW },
            offset: "100%",
            repeat: "30px",
          },
        ],
      });
      currentPolylines.push(polyline);
      polyline.setMap(mapREF.current);
    }

    const bounds = new mapsREF.current.LatLngBounds();

    bounds.extend(new mapsREF.current.LatLng(maxLat, maxLng));
    bounds.extend(new mapsREF.current.LatLng(minLat, minLng));
    mapREF.current.fitBounds(bounds);

    setTotalDistance(totalDistanceData);
    setTotalDuration(totalDurationData);
    polylinesREF.current = currentPolylines;
    setLoading(false);
  }, [enablePolyline, type, stops, origin, auth.token, t]);

  const fitBounds = useCallback(() => {
    if (!stops || stops.length === 0 || !origin?.location?.lat || !mapLoaded) {
      return;
    }
    const bounds = new mapsREF.current.LatLngBounds();

    bounds.extend(new mapsREF.current.LatLng(origin.location.lat, origin.location.lng));
    stops.forEach((place) => {
      bounds.extend(new mapsREF.current.LatLng(place.location.lat, place.location.lng));
    });
    mapREF.current.fitBounds(bounds);
  }, [stops, mapLoaded, origin]);

  useEffect(() => {
    if (!stops?.length || mapsREF.current === null || mapREF.current === null) {
      return;
    }
    if (!enablePolyline) {
      fitBounds();
    }
    drawPolylines();
  }, [stops, enablePolyline, drawPolylines, fitBounds]);

  const addtoDirections = (directions) => {
    for (let i = 0; i < directions.length; i++) {
      const direction = directions[i].directionDetails;

      const fromlat = direction.fromlat;
      const fromlng = direction.fromlng;
      const tolat = direction.tolat;
      const tolng = direction.tolng;
      const duration = direction.duration;
      const distance = direction.distance;

      const foundDirection = directionsREF.current.find(
        (direction) =>
          direction.fromlat === fromlat &&
          direction.fromlng === fromlng &&
          direction.tolat === tolat &&
          direction.tolng === tolng
      );

      if (foundDirection) {
        continue;
      }

      directionsREF.current.push({
        fromlat: fromlat,
        fromlng: fromlng,
        tolat: tolat,
        tolng: tolng,
        duration: duration,
        distance: distance,
      });
    }
  };

  const optimizeHandler2 = async () => {
    setLoading(true);

    const directionsToOrigin = await Promise.all(
      stops.map(async (stop) => {
        if (type.toString() === "100") {
          const directionDetails = await getDirection(stop, origin, auth.token);
          return { directionDetails, id: stop.id };
        } else {
          const directionDetails = await getDirection(origin, stop, auth.token);
          return { directionDetails, id: stop.id };
        }
      })
    );

    directionsToOrigin.sort((a, b) => b.directionDetails.distance - a.directionDetails.distance);
    const farthestId = directionsToOrigin[0].id;

    const startingPoint = {
      location: type.toString() === "100" ? stops.find((stop) => stop.id === farthestId).location : origin.location,
      id: type.toString() === "100" ? stops.find((stop) => stop.id === farthestId).id : origin.id,
    };
    const endPoint = {
      location: type.toString() === "100" ? origin.location : stops.find((stop) => stop.id === farthestId).location,
      id: type.toString() === "100" ? origin.id : stops.find((stop) => stop.id === farthestId).id,
    };

    const waypoints = stops
      .filter((stop) => stop.id !== farthestId)
      .map((stop) => ({ location: stop.location, id: stop.id }));

    let directionDetails = [];
    if (waypoints.length > 0) {
      directionDetails = await getDirectionsTSPDetails(startingPoint, endPoint, waypoints, auth.token);
    } else {
      const currentDirection = await getDirection(startingPoint, endPoint, auth.token);
      directionDetails = [{ currentDirection, id: startingPoint.id }];
    }

    const directionsToAdd = directionDetails.map((direction) => {
      return {
        directionDetails: direction,
      };
    });

    addtoDirections(directionsToAdd);

    let newStops = [];

    for (let i = 0; i < directionDetails.length; i++) {
      const stop = stops.find((stop) => stop.id === directionDetails[i].id);
      if (stop) {
        newStops.push(stop);
      }
    }

    if (type.toString() === "200") {
      newStops.push(stops.find((stop) => stop.id === farthestId));
    }

    const calculatedNewStops = calculateTransferTimes(newStops);

    onOptimize(calculatedNewStops);
  };

  const calculateTransferTimes = (newStops) => {
    let pickupbeforeseconds = 0;
    let currentStops = [...newStops];
    if (type.toString() === "100") {
      const lastDirection = directionsREF.current.find(
        (direction) =>
          direction.tolat === origin.location.lat &&
          direction.tolng === origin.location.lng &&
          direction.fromlat === currentStops[currentStops.length - 1].location.lat &&
          direction.fromlng === currentStops[currentStops.length - 1].location.lng
      );

      const lastDuration = lastDirection.duration || 0;
      pickupbeforeseconds = lastDuration;
      currentStops[currentStops.length - 1].pickupbeforeseconds = pickupbeforeseconds;
      currentStops[currentStops.length - 1].transferTime = subtractSecondsFromTime(
        transferStartFinishTime,
        pickupbeforeseconds
      );

      for (var z = currentStops.length - 1; z > 0; z--) {
        const stop = currentStops[z - 1];
        const nextStop = currentStops[z];

        const currentDirection = directionsREF.current.find(
          (direction) =>
            direction.fromlat === stop.location.lat &&
            direction.fromlng === stop.location.lng &&
            direction.tolat === nextStop.location.lat &&
            direction.tolng === nextStop.location.lng
        );
        const currentDuration = currentDirection.duration;

        pickupbeforeseconds += currentDuration;
        stop.pickupbeforeseconds = pickupbeforeseconds;
        stop.transferTime = subtractSecondsFromTime(transferStartFinishTime, pickupbeforeseconds);
      }
    }
    return currentStops;
  };

  const personPickupTimeChangeHandler = (event, personId) => {
    const person = stops.find((person) => person.id === personId);
    person.transferTime = event.target.value;
    onOptimize(stops);
  };

  const personUpHandler = (personId) => {
    const pos = stops.map((person) => person.id).indexOf(personId);
    let newPeople = [];
    for (let i = 0; i < stops.length; i++) {
      if (i === pos && i !== 0) {
        const prev = newPeople.pop();
        newPeople.push(stops[i]);
        newPeople.push(prev);
        continue;
      } else {
        newPeople.push(stops[i]);
      }
    }
    onOptimize(newPeople);
  };

  const personDownHandler = (personId) => {
    const pos = stops.findIndex((person) => person.id === personId);

    if (pos < stops.length - 1) {
      let temp = stops[pos];
      stops[pos] = stops[pos + 1];
      stops[pos + 1] = temp;
      onOptimize([...stops]);
    }
  };

  return (
    <React.Fragment>
      <GenericModal
        message={modalMessage}
        title={modalTitle}
        onClose={() => setShowModal(false)}
        showModal={showModal}
      />

      {enablePolyline && (
        <div style={{ width: "400px" }}>
          <div style={{ display: "flex", columnGap: "20px", marginTop: "10px", marginBottom: "5px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: "5px",
                height: "40px",
              }}
            >
              <span>
                {t("distance")}
                {":"}
              </span>
              {loading && (
                <ProgressBar
                  visible={true}
                  height="40"
                  width="60"
                  color="#4fa94d"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
              {!loading && <b> {!isNaN(totalDistance) ? `${(totalDistance / 1000).toFixed(0)} km` : "-"} </b>}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: "5px",
                height: "40px",
              }}
            >
              <span>
                {t("duration")}
                {":"}
              </span>
              {loading && (
                <ProgressBar
                  visible={true}
                  height="40"
                  width="60"
                  color="#4fa94d"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
              {!loading && <b> {!isNaN(totalDuration) ? `${(totalDuration / 60).toFixed(0)} dk` : "-"} </b>}
            </div>
          </div>
          {type.toString() === "200" && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#aaa",
                  fontWeight: "bold",
                  marginRight: "5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "100%",
                }}
              >
                {origin.name} - {transferStartFinishTime}
              </div>
            </div>
          )}

          {stops.map((person, index) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
              key={"calculatedUsers" + index}
            >
              <div style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    style={{
                      alignSelf: "flex-start",
                      backgroundColor: "black",
                      padding: "5px",
                      marginBottom: "5px",
                    }}
                    type="button"
                    onClick={() => personUpHandler(person.id)}
                  >
                    <div className="arrow-up"></div>
                  </button>
                  <button
                    style={{ alignSelf: "flex-start", backgroundColor: "black", padding: "5px" }}
                    type="button"
                    onClick={() => personDownHandler(person.id)}
                  >
                    <div className="arrow-down"></div>
                  </button>
                </div>

                <div
                  style={{
                    backgroundColor: "#bbb",
                    padding: "10px",
                    margin: "5px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                >
                  {index + 1 + ". " + person.name}
                </div>

                {type.toString() === "100" && (
                  <input
                    style={{
                      height: "30px",
                      width: "48px",
                      borderRadius: "1px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      marginRight: "5px",
                      outlineColor: "black",
                      borderColor: "black",
                    }}
                    type="text"
                    key={person.id + "-transferTime"}
                    id={person.id + "-transferTime"}
                    onInput={(e) => personPickupTimeChangeHandler(e, person.id)}
                    value={person?.transferTime || ""}
                  />
                )}
              </div>
              {person.polylineLabel && (
                <div style={{ marginBottom: "10px" }}>
                  {" "}
                  {">> "}
                  {person?.polylineLabel} {" >>"}
                </div>
              )}
            </div>
          ))}
          {type.toString() === "100" && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#aaa",
                  fontWeight: "bold",
                  marginRight: "5px",
                  marginTop: "10px",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "100%",
                }}
              >
                {origin.name} {transferStartFinishTime && <span>- {transferStartFinishTime} </span>}
              </div>
            </div>
          )}
        </div>
      )}

      <div className={isFullScreen ? "map-with-transfers-container" : "map-with-transfers-container"}>
        {enablePolyline && !isNaN(totalDistance) && !isNaN(totalDuration) && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "20px",
              alignItems: "baseline",
              marginBottom: "10px",
            }}
          >
            <div>
              <Button danger disabled={loading} onClick={optimizeHandler2}>
                {t("optimize")}
              </Button>
            </div>
          </div>
        )}
        <div className={isFullScreen ? "shared-service-map-container-big" : "shared-service-map-container"}>
          <GoogleMapReact
            bootstrapURLKeys={{
              language: "tr",
              region: "tr",
              libraries: ["drawing", "geometry", "encoding"],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            options={function (maps) {
              return {
                mapTypeControl: true,
                mapTypeId: "roadmap",
                mapTypeControlOptions: {
                  mapTypeIds: ["satellite", "roadmap"],
                  style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: maps.ControlPosition.TOP_LEFT,
                },
              };
            }}
            //defaultCenter={markerLocationMorning}
            center={mapCenter}
            zoom={mapZoom}
          >
            {origin && (
              <Marker
                Icon={SchoolIcon}
                lat={origin.location.lat}
                lng={origin.location.lng}
                info={origin.name}
                initialShow
              />
            )}

            {stops?.length &&
              stops?.map((stop, index) => {
                return (
                  <Marker
                    Icon={RoomIcon}
                    lat={stop?.location?.lat || 1.1}
                    lng={stop?.location?.lng || 1.1}
                    key={stop.id}
                    //   info={`${stop.name} - ${stop.users.map((user) => user.name)}`}
                    info={index + 1 + ". " + stop.name}
                    initialShow
                  />
                );
              })}
          </GoogleMapReact>
        </div>
      </div>
    </React.Fragment>
  );
});

export default Map;
