import React, { useContext, useState, useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import DatePicker from "shared/components/FormElements/DatePicker";
import { transfertypes } from "shared/util/types";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { CheckTimeString } from "shared/util/time";

const TransferNew = () => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [showAlert, setShowAlert] = useState(false);
  const alertMessageRef = useRef("");

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateStoreItems = store.UpdateTransfers;
  const ItemTypeTR = "Transfer";
  const APIEndPoint = "transfers";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    if (!CheckTimeString(formState.inputs.transfertime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          type: formState.inputs.type.value,
          school: formState.inputs.school.value,
          transferdate: formState.inputs.transferdate.value,
          transfertime: formState.inputs.transfertime.value,
          done: formState.inputs.done.value,
          vehicleRoutes: [],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
      name: { value: "", isValid: false },
      type: { value: "", isValid: false },
      transferdate: { value: "", isValid: false },
      transfertime: { value: "", isValid: false },
      done: { value: false, isValid: true },
      vehicleRoutes: { value: [], isValid: true },
    },
    false
  );

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      <div>
        <form className="form-container" style={{ maxWidth: "600px" }} onSubmit={itemSubmitHandler}>
          {(isLoading || !store.studentOptions) && <LoadingSpinner asOverlay />}
          <CustomSelect
            options={store.schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Kurum"
            validators={[VALIDATOR_REQUIRE()]}
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label="Transfer İsmi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Transfer ismi giriniz."
            onInput={inputHandler}
          />
          <CustomSelect options={transfertypes} id="type" onInput={inputHandler} label="Transfer Tipi"></CustomSelect>

          <DatePicker
            id="transferdate"
            onInput={inputHandler}
            label="Transfer Tarihi"
            validators={[VALIDATOR_REQUIRE()]}
          ></DatePicker>

          <Input
            id="transfertime"
            element="input"
            type="text"
            label="Havalimanında olma saati"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
            onInput={inputHandler}
          />
          <br />
          <Button type="submit" disabled={!formState.isValid}>
            Yeni {ItemTypeTR} Yarat
          </Button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default TransferNew;
