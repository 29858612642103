import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "24px" }}>
      <h1 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>{t("privacy.privacyPolicy")}</h1>
      <p style={{ marginBottom: "16px" }}>{t("privacy.privacyPolicyIntro")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.informationWeCollect")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.personalData")}</p>
      <p style={{ marginBottom: "16px" }}>{t("privacy.usageData")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.howWeUseInformation")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.provideImprovePlatform")}</p>
      <p style={{ marginBottom: "16px" }}>{t("privacy.communicateWithUsers")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.dataSharingDisclosure")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.noSellUserData")}</p>
      <p style={{ marginBottom: "16px" }}>{t("privacy.serviceProviders")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.dataSecurity")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.securityMeasures")}</p>
      <p style={{ marginBottom: "16px" }}>{t("privacy.noMethod100Secure")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.userRights")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.accessUpdateDelete")}</p>
      <p style={{ marginBottom: "16px" }}>{t("privacy.contactForRequests")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.changesToPolicy")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.policyUpdates")}</p>
      <p style={{ marginBottom: "16px" }}>{t("privacy.userNotifications")}</p>

      <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "16px" }}>{t("privacy.contactInformation")}</h2>
      <p style={{ marginBottom: "16px" }}>{t("privacy.contactUsAt")}</p>
    </div>
  );
};

export default PrivacyPolicy;
